import moment from "moment";

const getFormattedDate = (dateToConvert: any) => {
  const formattedDate = moment(dateToConvert, "DD/MM/YYYY").format(
    "DD MMMM, YYYY"
  );

  return formattedDate;
};

const getStats = (stats, field) => {
  const updatedStats = stats.map((stat) => {
    if (stat["admin_edits"] >= stat[field]) {
      return stat["admin_edits"];
    } else if (stat["admin_edits"] < stat[field]) {
      return stat[field];
    }

    return 0;
  });

  return updatedStats;
};

const parse = (response: any, recruiterId) => {
  const contest = response.data.attributes;

  const parsedContestDetails: any = {};

  const currentDate = new Date().getTime();

  const postedDate = new Date(contest["date"]).getTime();

  const postedDuration = Math.floor(
    (currentDate - postedDate) / (1000 * 24 * 60 * 60)
  );

  const endDate = moment(
    contest["rounds"]["countdown"]["timer"],
    "DD/MM/YYYY hh:mm a"
  ).toDate();

  parsedContestDetails.id = response.data.id;
  parsedContestDetails.jobPostedDuration = `${
    postedDuration === 0 ? "Today" : `${postedDuration}d ago`
  }`;

  // Card Details
  parsedContestDetails.badge = contest["select_badge"];
  parsedContestDetails.employmentType =
    contest["card_elements"]["card_taglines"];
  parsedContestDetails.jobTitle = contest["contest_title"];
  parsedContestDetails.cashReward = `₹${contest["bounty"].toLocaleString(
    "en-IN"
  )}`;
  parsedContestDetails.buttonText = contest["card_elements"]["button_text"];
  parsedContestDetails.buttonEnabled = !contest["card_elements"]["disable_button"];
  parsedContestDetails.company = contest["about_employer"][1];
  parsedContestDetails.experience = contest["experience"]
    ? `${contest["experience"]["from"]} to ${contest["experience"]["to"]} yrs`
    : null;
  parsedContestDetails.tools = contest["primary_skills"].join(", ");
  parsedContestDetails.noticePeriod = `${contest["required_notice_period"]} ${
    contest["required_notice_period"] > 1 ? "days" : "day"
  } Of Notice Period`;
  parsedContestDetails.locations = contest["contest_locations"].join(", ");
  parsedContestDetails.interviewDays = contest["drive_type"];
  parsedContestDetails.positions = `${contest["number_of_position"]} Positions`;
  const budgetUnit =
    contest["budget_ctc"]["units"] === "thousands" ? "KPA" : "LPA";
  parsedContestDetails.ctc = `${contest["budget_ctc"]["from"]}${budgetUnit} to ${contest["budget_ctc"]["to"]}${budgetUnit}`;

  parsedContestDetails.skills = contest["secondary_skills"].join(", ");
  parsedContestDetails.degree = contest["qualification"].join(", ");
  parsedContestDetails.tags = contest["contest_tags"].join(", ");
  parsedContestDetails.domains = contest["contest_domain"].join(", ");

  parsedContestDetails.iconsToShow = contest["icon_customization"];
  parsedContestDetails.iconStatus =
    recruiterId > 0 ? contest[`recruiter_${recruiterId}_status`] : "";

  // Timer
  parsedContestDetails.endDate = endDate;

  // Quota
  parsedContestDetails.bonus = `₹${Number(contest["bonus"]).toLocaleString(
    "en-IN"
  )}`;
  parsedContestDetails.quota = contest["total_application_limit_per_contest"];

  //Timeline
  const timelineData = [];
  timelineData.push({
    date: getFormattedDate(contest["rounds"]["start_date"]),
    event: "Start",
  });
  contest["rounds"]["rounds"].map((round) => {
    timelineData.push({
      date: getFormattedDate(round["date"]),
      event: round["title"],
      isActiveRound: round["is_active_round"],
    });
  });
  timelineData.push({
    date: getFormattedDate(contest["rounds"]["end_date"]),
    event: "End",
  });
  parsedContestDetails.timelineData = timelineData;

  // Tabs
  // Job Description
  parsedContestDetails.tasks = contest["roles_and_responsibilities"];
  parsedContestDetails.jobSkills = contest["skills"];
  parsedContestDetails.background = contest["background"];
  parsedContestDetails.jobDescription = contest["full_job_description"];

  // Overview
  parsedContestDetails.aboutContest = contest["about_contest"];
  parsedContestDetails.contestStats = getStats(
    contest["statistics"][0].stats,
    "contest_stats"
  );
  parsedContestDetails.contestMetrics = getStats(
    contest["contest_metrics"][0].stats,
    "contest_metrics"
  );

  // About Employer
  parsedContestDetails.employerId = contest["about_employer"][0];

  // Partners
  parsedContestDetails.partners = contest["partners"]
    ? contest["partners"]
    : [];

  // Rewards
  const serviceFee = contest["reward"].map((reward) => {
    return {
      Attribute: reward.week,
      Value: reward.placement_fee,
    };
  });
  const rewardsDescription = contest["reward_descriptions"];
  const consolationPrizes =
    contest["consolation_prizes"].length > 0
      ? contest["consolation_prizes"].map((reward) => {
          const rewards = [];
          if (reward["cash_prize"]) {
            rewards.push(`cash-${reward["cash_prize"]}`);
          }
          if (reward["certificate"] === "yes") {
            rewards.push("certificate");
          }
          if (reward["coupons"]["enter_name"]) {
            rewards.push("coupon");
          }
          if (reward["swag"].length > 0) {
            rewards.push(`swag@${reward["swag"].toString()}`);
          }
          return {
            Attribute: reward.jobseeker_leaving_period,
            Value: rewards,
          };
        })
      : [];

  const overAllStatistics: any = {};
  overAllStatistics.participants = `${Number(
    contest["overall_statistics"]["participants"]
  ).toLocaleString("en-IN")}`;
  overAllStatistics.consolationPrizes = `₹${Number(
    contest["overall_statistics"]["consolation_prizes"]
  ).toLocaleString("en-IN")}`;
  overAllStatistics.rewardsAmount = `₹${Number(
    contest["overall_statistics"]["rewards"]
  ).toLocaleString("en-IN")}`;
  overAllStatistics.candidatesPlaced = `${Number(
    contest["overall_statistics"]["candidates_placed"]
  ).toLocaleString("en-IN")}`;
  overAllStatistics.certificatesOffered = `${Number(
    contest["overall_statistics"]["certificates_offered"]
  ).toLocaleString("en-IN")}`;

  parsedContestDetails.rewards = {
    serviceFee,
    rewardsDescription,
    consolationPrizes,
    overAllStatistics,
  };

  // FAQ
  parsedContestDetails.faq = contest["frequently_asked_questions"];

  // Terms & Conditions
  parsedContestDetails.termsAndConditions = contest["copy_and_paste_tnc"];

  parsedContestDetails.contestUrl = contest["contest_url"];

  return parsedContestDetails;
};

export default parse;
