import { useEffect, useState } from "react";
import Button from "../Button";
import { fetchOptions, sendPostRequest, linksRequest } from "./Api";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@mui/icons-material/Close";
import { getLocalStorage } from "../../utils/storage-manager";
import Toast from "../../utils/toast";

const Verification = ({ onActiveFormChange }) => {
  const [form, setForm] = useState({
    cinNo: "",
    gstNo: "",
    panNo: "",
    tanNo: "",
    bankName: "",
    AccName: "",
    AccNo: "",
    ifsc: "",
    branchName: "",
    selectSupportedDoc: [],
    uploadDoc: [],
    uploadDocUrl: [],
  });

  const selectedSupportedDoc = Object.entries(form.selectSupportedDoc).reduce(
    (acc, [key, value], index) => {
      acc[index] = key;
      return acc;
    },
    {}
  );

  const [supportedDocOp, setSupportedDocOp] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const supportedDocKey = "field_64ddc436877e5";
    fetchOptions(supportedDocKey).then((options) => {
      setSupportedDocOp(options);
    });
  }, []);

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    const files = e.target.files;
    if (!files) {
      console.log("No files to upload.");
      return;
    }
    const file = new FormData();

    for (let i = 0; i < files.length; i++) {
      file.append(e.target.name, files[i]);
    }
    const response = await fetch(
      `${process.env.REACT_APP_URL_PREFIX}/file/upload`,
      {
        method: "POST",
        body: file,
      }
    );

    if (response.ok) {
      const jsonData = await response.json();
      const attachmentId = jsonData.data.id;
      const attachementUrl = jsonData.data.url;
      Toast.Success("File Uploaded Successfully");
      setForm((prevState) => ({
        ...prevState,
        uploadDoc: attachmentId,
        uploadDocUrl: attachementUrl,
      }));
    } else {
      Toast.Error("File upload failed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      cin_number: form.cinNo,
      gst_number: form.gstNo,
      pan_number: form.panNo,
      tan_number: form.tanNo,
      bank_details: {
        bank_name: form.bankName,
        account_holder_name: form.AccName,
        account_number: form.AccNo,
        ifsc_code: form.ifsc,
        branch: form.branchName,
      },
      all_supporting_documents: selectedSupportedDoc,
      supporting_documents: [
        {
          title: "",
          links: form.uploadDocUrl,
          supporting_attachment: form.uploadDoc,
        },
      ],
    };
    if (Object.keys(errors).length === 0) {
      onActiveFormChange("TermsToUse");
      const id = getLocalStorage("id");
      sendPostRequest(`${id}/company/verification`, formData);
    }
  };

  const errorHandler = () => {
    const newErrors = {};
    const CIN_REGEX = /^(L|U)[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
    if (form.cinNo && !CIN_REGEX.test(form.cinNo)) {
      newErrors.cinNo = "Please enter a valid CIN Number";
    }

    const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (form.gstNo && !GST_REGEX.test(form.gstNo)) {
      newErrors.gstNo = "Please enter a valid GST Number";
    }

    const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (form.panNo && !PAN_REGEX.test(form.panNo)) {
      newErrors.panNo = "Please enter a valid PAN Number";
    }

    const TAN_REGEX = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (form.tanNo && !TAN_REGEX.test(form.tanNo)) {
      newErrors.tanNo = "Please enter a valid TAN Number";
    }

    const ACCOUNT_NUMBER_REGEX = /^\d{10,15}$/;
    if (form.AccNo && !ACCOUNT_NUMBER_REGEX.test(form.AccNo)) {
      newErrors.AccNo = "Please enter a valid Account Number";
    }

    const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (form.ifsc && !IFSC_REGEX.test(form.ifsc)) {
      newErrors.ifsc = "Please enter a valid Account Number";
    }

    setErrors(newErrors);
  };

  const backHandler = () => {
    onActiveFormChange("ExperienceDetails");
    console.log(form.uploadDoc);
  };
  const skipHandler = () => {
    onActiveFormChange("TermsToUse");
  };

  return (
    <form onSubmit={handleSubmit}>
      <button className="skip" onClick={skipHandler}>
        Skip
      </button>
      <div style={{ marginTop: "2%" }}>
        <div style={{ display: "flex", gap: "10%" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className={`label ${errors.cinNo ? "error" : ""}`}>
              CIN No <nspb />
              {errors.cinNo && (
                <span className="error-message">{errors.cinNo}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.cinNo ? "error" : ""}`}
              type="text"
              name="cinNo"
              placeholder="Type Here..."
              value={form.cinNo}
              onChange={handleChange}
              onBlur={errorHandler}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className={`label ${errors.gstNo ? "error" : ""}`}>
              GST No <nspb />
              {errors.gstNo && (
                <span className="error-message">{errors.gstNo}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.gstNo ? "error" : ""}`}
              type="text"
              name="gstNo"
              placeholder="Type Here..."
              value={form.gstNo}
              onChange={handleChange}
              onBlur={errorHandler}
              style={{ width: "84%" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "10%" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className={`label ${errors.panNo ? "error" : ""}`}>
              PAN No <nspb />
              {errors.panNo && (
                <span className="error-message">{errors.panNo}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.panNo ? "error" : ""}`}
              type="text"
              name="panNo"
              placeholder="Type Here..."
              value={form.panNo}
              onChange={handleChange}
              onBlur={errorHandler}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className={`label ${errors.tanNo ? "error" : ""}`}>
              TAN No <nspb />
              {errors.tanNo && (
                <span className="error-message">{errors.tanNo}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.tanNo ? "error" : ""}`}
              type="text"
              name="tanNo"
              placeholder="Type Here..."
              value={form.tanNo}
              onChange={handleChange}
              onBlur={errorHandler}
              style={{ width: "84%" }}
            />
          </div>
        </div>

        <label className="label">Bank Name</label>
        <input
          className={`input-field ${errors.bankName ? "error" : ""}`}
          type="text"
          name="bankName"
          placeholder="Type Here..."
          value={form.bankName}
          onChange={handleChange}
        />

        <label className="label">Account Name as per the Bank records</label>
        <input
          className={`input-field ${errors.AccName ? "error" : ""}`}
          type="text"
          name="AccName"
          placeholder="Type Here..."
          value={form.AccName}
          onChange={handleChange}
        />

        <label className={`label ${errors.AccNo ? "error" : ""}`}>
          Account Number <nspb />
          {errors.AccNo && (
            <span className="error-message">{errors.AccNo}</span>
          )}
        </label>
        <input
          className={`input-field ${errors.AccNo ? "error" : ""}`}
          type="text"
          name="AccNo"
          placeholder="Type Here..."
          value={form.AccNo}
          onBlur={errorHandler}
          onChange={handleChange}
        />
        <div style={{ display: "flex", gap: "10%" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className={`label ${errors.ifsc ? "error" : ""}`}>
              IFSC <nspb />
              {errors.ifsc && (
                <span className="error-message">{errors.ifsc}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.ifsc ? "error" : ""}`}
              type="text"
              name="ifsc"
              placeholder="Type Here..."
              value={form.ifsc}
              onChange={handleChange}
              onBlur={errorHandler}
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className="label">Branch Name</label>
            <input
              className={`input-field ${errors.branchName ? "error" : ""}`}
              type="text"
              name="branchName"
              placeholder="Type Here..."
              value={form.branchName}
              onChange={handleChange}
              style={{ width: "84%" }}
            />
          </div>
        </div>

        <label className="label">
          Select all the those supporting documents you have from the below list
        </label>
        <Multiselect
          options={Object.entries(supportedDocOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(
            form.selectSupportedDoc
          ).map(([key, value]) => ({ key, value }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, selectSupportedDoc: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, selectSupportedDoc: newSelected });
          }}
          placeholder="Select"
          //showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              // To change css for multiselect (Width,height,etc..)
              width: "97.3%",
              paddingTop: "4px",
              paddingBottom: "3px",
            },
            searchBox: {
              // To change search box element look
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              // To change input field position or margin
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              // To change css for option container
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />

        <label className="label">
          Please upload all the selected documents above into one PDF/ Doc{" "}
        </label>
        <input
          className={`input-field ${errors.uploadDoc ? "error" : ""}`}
          type="file"
          multiple
          name="file"
          onChange={handleChange}
        />
      </div>
      <div className="nextBtn">
        <Button
          label="Next"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="submit"
        />
      </div>
      <div className="backBtn">
        <Button
          label="Back"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="Back"
          onClick={backHandler}
        />
      </div>
    </form>
  );
};
export default Verification;
