import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "../Button";
import TextValidator from "../TextValidator";
import PhoneNumberValidator from "../PhoneNumberValidator";
import * as Constants from "../../utils/constants/index";
import styles from "./style.module.scss";

const Contact = (props) => {
  const {
    onClose,
    showComapnyAndDesignation = true,
    showMessage = false,
  } = props;
  const [open, setOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name cannot be empty"),
    phoneNumberExt: Yup.string()
      .trim()
      .notRequired()
      .default("+91")
      .matches(Constants.PHONE_EXT_REGEX, "Phone extension is not valid"),
    phoneNumber: Yup.string()
      .trim()
      .required("Phone number is required")
      .matches(Constants.PHONE_REGEX, "Phone number is not valid"),
    email: Yup.string()
      .trim()
      .email("Email address is invalid")
      .required("Email address is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  const formData = {
    name: "",
    phoneNumber: "",
    email: "",
    phoneNumberExt: "+91",
    company: "",
    designation: "",
    message: "",
  };

  const [values, setValues] = useState(formData);

  const resetForm = () => {
    setValues({
      name: "",
      phoneNumber: "",
      email: "",
      phoneNumberExt: "+91",
      company: "",
      designation: "",
      message: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
    setErrorMessage(null);
    setSuccessMessage(null);
    onClose();
  };

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [formItem]: event.target.value });
    setValue(formItem, event.target.value, { shouldValidate: true });

    return;
  };

  const handleMessageChange = (formItem: string, value: any) => {
    setValues({ ...values, [formItem]: value });
    return;
  };

  const isRequiredFieldMissing = () => {
    let requiredFields = [
      "name",
      "phoneNumber",
      "email",
      "phoneNumberExt",
      "designation",
    ];

    if (showMessage && !showComapnyAndDesignation) {
      requiredFields = [
        "name",
        "phoneNumber",
        "email",
        "phoneNumberExt",
        "message",
      ];
    }

    if (showMessage && showComapnyAndDesignation) {
      requiredFields = [
        "name",
        "phoneNumber",
        "email",
        "phoneNumberExt",
        "message",
        "designation",
      ];
    }

    const missingFields = Object.keys(values).filter((item) => {
      return !values[item];
    });

    return requiredFields.some((element) => {
      return missingFields.includes(element);
    });
  };

  const handleFormSubmit = () => {
    const to = process.env.REACT_APP_SUPPORT_EMAIL;
    const subject = "Callback Request";

    // Following will be the structure of the constructed message:
    /* 
      Hi Team,

      <Name>, working as a <Designation> for <Company> has requested a callback on <PhoneNumber> to know more about Hiringhood. You can also reach them on <EmailAddress>

      Regards,
      Hiringhood Tech Support

      THIS IS AN AUTO-GENERATED EMAIL. PLEASE DO NOT REPLY TO THIS EMAIL
    */

    const message = `<html> Hi Team, <br/><br/>${values.name} ${
      values.designation ? `, working as a ${values.designation}` : ""
    }${
      values.company ? ` for ${values.company}` : " "
    } has requested a callback on ${values.phoneNumberExt}-${
      values.phoneNumber
    } to know more about Hiringhood. You can also reach them on ${
      values.email
    } <br/> ${
      values.message
        ? `<br/>The following message has been provided in the Contact Us form <br/><br/> <b>"${values.message}"</b>`
        : ""
    } <br/><br/> Regards, 
    <br/>Hiringhood Tech Support<br/><br/><br/> THIS IS AN AUTO-GENERATED EMAIL. PLEASE DO NOT REPLY TO THIS EMAIL.</html>`;

    setLoading(true);

    fetch(process.env.REACT_APP_EMAIL_SERVICE_API, {
      method: "POST",
      body: JSON.stringify({
        to: [to],
        subject,
        message,
      }),
    })
      .then((data) => {
        resetForm();
        setSuccessMessage(
          "Your details have been saved. Our team will get in touch with you shortly."
        );
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        resetForm();
        setErrorMessage(
          "There was an error saving your details. Please try again later"
        );
        setLoading(false);
      });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} hideBackdrop>
        <div className={styles["contact-popup-container"]}>
          {!successMessage && (
            <>
              <p className={styles["contact-popup-title"]}>
                Please fill the following details
              </p>
              <form>
                <TextValidator
                  id="name"
                  label="Name*"
                  className={styles["contact-popup-input"]}
                  value={values.name}
                  register={register}
                  onChange={handleChange("name")}
                  errorMessage={errors.name?.message.toString()}
                />
                <div className={styles["contact-popup-input"]}>
                  <PhoneNumberValidator
                    id="phoneNumber"
                    label="Phone Number*"
                    register={register}
                    phone={values.phoneNumber}
                    phoneExt={values.phoneNumberExt}
                    onPhoneChange={handleChange("phoneNumber")}
                    onExtChange={handleChange("phoneNumberExt")}
                    // errorMessage={
                    //   errors.phoneNumberExt
                    //     ? errors.phoneNumberExt.message
                    //     : errors.phoneNumber?.message.toString()
                    // }
                  />
                </div>
                <TextValidator
                  id="email"
                  label="Email Address*"
                  className={styles["contact-popup-input"]}
                  value={values.email}
                  register={register}
                  onChange={handleChange("email")}
                  // errorMessage={errors.email?.message.toString()}
                />
                {showComapnyAndDesignation && (
                  <TextValidator
                    id="designation"
                    label="Designation*"
                    className={styles["contact-popup-input"]}
                    value={values.designation}
                    register={register}
                    onChange={handleChange("designation")}
                    errorMessage={errors.designation?.message}
                  />
                )}
                {showComapnyAndDesignation && (
                  <TextValidator
                    id="company"
                    label="Company"
                    className={styles["contact-popup-input"]}
                    value={values.company}
                    register={register}
                    onChange={handleChange("company")}
                  />
                )}
                {showMessage && (
                  <>
                    <p className={styles["contact-message-label"]}>Message*</p>
                    <textarea
                      className={styles["contact-message"]}
                      rows={10}
                      name="message"
                      value={values.message}
                      {...register("message")}
                      onChange={(event) => {
                        handleMessageChange("message", event.target.value);
                      }}
                    />
                    <p className={styles["contact-message-error"]}>
                      {errors.message?.message}
                    </p>
                  </>
                )}
              </form>
              <div className={styles["contact-popup-button-container"]}>
                <Button
                  label="Close"
                  variant="outlined"
                  onClick={handleClose}
                />
                <LoadingButton
                  className={styles["contact-submit-button"]}
                  variant="contained"
                  disabled={!isValid || isRequiredFieldMissing()}
                  sx={{ backgroundColor: "#4D6CD9" }}
                  onClick={handleFormSubmit}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </div>
              <p className={styles["contact-popup-error-message"]}>
                {errorMessage}
              </p>
            </>
          )}
          {successMessage && (
            <>
              <p className={styles["contact-popup-success-message"]}>
                {successMessage}
              </p>
              <div className={styles["contact-popup-button-container"]}>
                <Button
                  label="Close"
                  variant="outlined"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Contact;
