import { useState } from "react";
import Button from "../Button";
import { sendPostRequest, linksRequest, sendPost } from "./Api";
import { getLocalStorage, setLocalStorage } from "../../utils/storage-manager";

const PersonalDetails = ({ onActiveFormChange,form, setForm }) => {
  const userEmail = getLocalStorage("userEmail");

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(form).forEach((key) => {
      if (!form[key]) {
        newErrors[key] = "This field is required";
      }
    });

    setErrors(newErrors);
    setLocalStorage("companyName", form.companyName);
    const formData = {
      type: "company-recruiter",
      display_name: form.fullName,
      designation: form.designation,
      email_address: userEmail,
      official_email_address: form.officialEmail,
      mobile_number: form.mobile,
      company: {
        rc_legal_spoc: {
          address: form.address,
        },
        rc_registerd_name: form.companyName,
      },
    };

    if (Object.keys(errors).length === 0) {
      console.log("form personal", form);
      onActiveFormChange("professional");
      sendPostRequest("", formData).then((response) => {
        const generatedId = response.data.data.id;
        const displayName = response.data.data.attributes.display_name;
        setLocalStorage("id", generatedId);
        setLocalStorage("user", displayName);
        console.log(generatedId);
      });
      linksRequest("links").then((response) => {
        const dashboardUrl = response.data.data.dashboard;
        setLocalStorage("dashboardUrl", dashboardUrl);
      });
    }
  };

  const errorHandler = () => {
    const newErrors = {};

    const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (form.email && !EMAIL_REGEX.test(form.email)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }

    if (form.officialEmail && !EMAIL_REGEX.test(form.officialEmail)) {
      newErrors.officialEmail = "Please enter a valid email address";
    } else {
      delete newErrors.officialEmail;
    }

    const phoneRegex = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
    if (form.mobile && !phoneRegex.test(form.mobile)) {
      newErrors.mobile = "Please enter a valid mobile number";
    } else {
      delete newErrors.mobile;
    }
    setErrors(newErrors);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginTop: "5%" }}>
        <label className="label required">Full Name</label>
        <input
          className={`input-field ${errors.fullName ? "error" : ""}`}
          type="text"
          required
          name="fullName"
          placeholder="Full Name"
          value={form.fullName}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
        />
        <label className="label required">Designation</label>
        <input
          className={`input-field ${errors.designation ? "error" : ""}`}
          type="text"
          required
          name="designation"
          placeholder="Designation"
          value={form.designation}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
        />

        <label className={"label required"}>Email </label>
        <input
          className={"input-field"}
          type="text"
          required
          name="email"
          value={userEmail}
          disabled
          onChange={handleChange}
          style={{
            background: "#e9ecef",
            opacity: "0.6",
            cursor: "not-allowed",
          }}
          onBlur={errorHandler}
        />

        <label className="label">
          Official Email Address
          {errors.officialEmail && (
            <span className="error-message">{errors.officialEmail}</span>
          )}
        </label>
        <input
          className={`input-field ${errors.officialEmail ? "error" : ""}`}
          type="text"
          name="officialEmail"
          placeholder="officialEmail"
          value={form.officialEmail}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
          onBlur={errorHandler}
        />

        <label className={`label required ${errors.mobile ? "error" : ""}`}>
          Mobile
          <nspb />
          {errors.mobile && (
            <span className="error-message">{errors.mobile}</span>
          )}
        </label>
        <input
          className={`input-field ${errors.mobile ? "error" : ""}`}
          type="text"
          required
          inputMode="Numeric"
          name="mobile"
          placeholder="mobile"
          value={form.mobile}
          style={{ marginBottom: "1rem" }}
          onBlur={errorHandler}
          onChange={handleChange}
        />

        <label className={"label required "}>Company Name</label>
        <input
          className={`input-field ${errors.companyName ? "error" : ""}`}
          type="text"
          required
          name="companyName"
          placeholder="Company Name"
          value={form.companyName}
          // onBlur={handleSubmit}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
        />

        <label className="label required"> Corresponding Address</label>
        <textarea
          className={`input-field ${errors.address ? "error" : ""}`}
          required
          name="address"
          placeholder="Enter Address"
          value={form.address}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
        />
      </div>
      <div className="nextBtn">
        <Button
          label="Next"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="submit"
        />
      </div>
    </form>
  );
};
export default PersonalDetails;
