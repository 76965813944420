import taskImage from "../../assets/task.svg";
import backgroundImage from "../../assets/background.svg";
import skillImage from "../../assets/skill.svg";
import "./styles.css";
import { getLocalStorage } from "../../utils/storage-manager";
import axios from "axios";
import { useState } from "react";
import { CONTEST_API } from "../../utils/constants/urls";

const JobDescription = (props: any) => {
  const { description, tasks, skills, background } = props;
  interface HttpResponse {
    data: {
      attributes: {
        google_sheet_embed_job_description: string;
      };
    };
  }
  const [googleLink, Setgooglelink] = useState("");
  const renderTask = (icon, label, content) => {
    return (
      <div className="job-description-task-container">
        <img src={icon} alt={label} />
        <p className="job-description-task-title">{label}</p>
        <div
          className="job-description-task-content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    );
  };
  const id = getLocalStorage("contestId", null);
 
  const apiUrl = `${CONTEST_API}/${id}`;
  axios
    .get<HttpResponse>(apiUrl)
    .then((response) => {
      const googleSheetLink =
        response.data.data.attributes.google_sheet_embed_job_description;
      Setgooglelink(googleSheetLink);
      console.log(googleSheetLink);
    })
    .catch((error) => {
      console.error("Error fetching options:", error);
      return [];
    });

  return (
    <div className="job-description-container">
      <div className="job-description-tasks-container">
        {renderTask(taskImage, "Roles and Responsibilities", tasks)}
        {renderTask(backgroundImage, "Background", background)}
        {renderTask(skillImage, "Skills", skills)}
      </div>
      <iframe src={googleLink} title="Google Sheet" />
      <p className="job-description-title">Full Job Description</p>
      <div
        className="job-description-content"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
    </div>
  );
};

export default JobDescription;
