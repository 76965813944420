import { useState } from "react";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import loginImage from "../../assets/signup.png";
import userIcon from "../../assets/user.svg";
import Card from "../Card";
import "./styles.scss";
import { validateEmail, validateOtpInput } from "../../utils/validators";
import OtpInput from "react-otp-input";
import { SIGNUP, CONTESTS, CONTEST } from "../../utils/constants/routes";
import { Link, useHistory } from "react-router-dom";
import { Timer } from "../../utils/timer";
import LoadingButton from "@mui/lab/LoadingButton";
import eyeOpenIcon from "../../assets/eye-open.svg";
import eyeClosedIcon from "../../assets/eye-closed.svg";
import ErrorBoundary from "../../utils/errorBoundary";
import api from "../../utils/api/apis";
import Toast from "../../utils/toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setAuthenticationStatus } from "../../redux/actions/login";
import { setLocalStorage } from "../../utils/storage-manager";
import { COPYRIGHT } from "../../utils/constants/index";
import Button from "@mui/material/Button";
import linkedin from "../../assets/linkedin-color.svg";
import google from "../../assets/google.svg";
import { GoogleLogin } from "react-google-login";
import { setCurrentUser } from "../../redux/actions/user";

const SCREENS = {
  LOGIN: "login-view",
  OTP: "otp-view",
  ENTER_PASSWORD: "enter-password-view",
  FORGOT_PASSWORD: "forgot-password-view",
  FORGOT_PASSWORD_OTP: "forgot-password-otp-view",
  CHANGE_PASSWORD: "change-password-view",
};
const STRINGS = {
  LOGIN: {
    LOGIN: "Login",
    DONT_HAVE_ACCOUNT: "Don’t have an account?",
    SIGNUP_NOW: " Sign Up now",
    COPY_RIGHT: COPYRIGHT,
    WELCOME: "Welcome",
    LOGIN_WITH_YOUR_ACCOUNT: "Login Your Account with",
    ENTER_EMAIL_ID: "Enter Email ID",
    INVALID_EMAIL: "Invalid Email Address",
    SEND_OTP: "Send OTP",
    OR_ENTER_PASSWORD: "Or Enter Password",
    BACK: "Back",
    ENTER_OTP: "Enter OTP",
    OTP_INFO: "4 digits OTP has been sent to your Email Address",
    OTP_INVALID: "OTP is invalid, please try again",
    RESEND_OTP: "Resend OTP",
    ENTER_PASSWORD: "Enter Password",
    ENTER_PASSWORD_DESC: "Please enter the password to proceed",
    LOGIN_NOW: "Login Now",
    FORGOT_PASSWORD: "Forgot Password?",
    RECOVER_PASSWORD: "Recover your password",
    RECOVER_PASSWORD_DESC: "Please enter your email address",
    REMEMBER_PASSWORD: "Remember password? Login now",
  },
};

const SignUpNow = () => {
  return (
    <div className="bottom-signup-container">
      <div className="bottom-signup-label">
        {STRINGS.LOGIN.DONT_HAVE_ACCOUNT}
        <Link to={SIGNUP}>
          <span className="bottom-signup-label-link">
            &nbsp;{STRINGS.LOGIN.SIGNUP_NOW}
          </span>
        </Link>
      </div>
    </div>
  );
};

const CopyRight = () => {
  return <p className="login-copyright">{STRINGS.LOGIN.COPY_RIGHT}</p>;
};

const Login = () => {
  const history = useHistory();

  const location = useLocation();

  const state: any = location && location.state ? location.state : {};

  const contestId = state.contestId;

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [currentView, setCurrentView] = useState(SCREENS.LOGIN);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendOtpActive, setResendOtpActive] = useState(false);
  const [restartTimer, setRestartTimer] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [socialError, setSocialError] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      validateEmail(event.target.value)
        ? setIsInvalidEmail(false)
        : setIsInvalidEmail(true);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getProfileImage = (response) => {
    const isFreelanceRecruiter =
      response["attributes"]["role"] === "freelance-recruiter";
    const profileImage = isFreelanceRecruiter
      ? response["attributes"] && response["attributes"]["upload_profile_image"]
        ? response["attributes"]["upload_profile_image"]["url"]
        : null
      : response["company"]["attributes"] &&
        response["company"]["attributes"]["logo"]
      ? response["company"]["attributes"]["logo"]["url"]
      : null;

    return profileImage;
  };

  const setDataInLocalStorage = (response) => {
    const { ...rest } = response.data.data.attributes;

    const name = rest["display_name"];

    setLocalStorage("user", name);
    setLocalStorage("userId", rest["ID"]);
    setLocalStorage("userRole", rest["role"]);
    setLocalStorage("userStage", rest["user_stage"]);
    setLocalStorage("inviteSource", rest["invite_source"]);
  };

  const onSendOTP = () => {
    setLoading(true);
    const payload = {
      email: values.email,
      type: "otp",
    };
    api
      .login(payload)
      .then((res) => {
        if (res.status === 200) {
          setCurrentView(SCREENS.OTP);
        } else if (res.status >= 400) {
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error", e);
      });
  };

  const backClicked = () => {
    setCurrentView(SCREENS.LOGIN);
    setInvalidOtp(false);
    setOtp("");
  };

  const handleOtpInput = (otp) => {
    setOtp(otp);
    if (validateOtpInput(otp)) {
      setInvalidOtp(false);
    } else {
      setInvalidOtp(true);
    }
  };

  const onOtpLoginClick = (type) => {
    const payload = {
      email: values.email,
      otp: otp,
    };
    api
      .validateOtp(payload)
      .then((res) => {
        if (res.status === 200) {
          const headers = res.headers;
          const { wp_auth } = headers;
          const cookieHeader = JSON.parse(wp_auth);
          const cookies = new Cookies();
          cookies.set(cookieHeader.cookie_name, cookieHeader.value, {
            domain: cookieHeader.cookie_domain,
            path: cookieHeader.cookie_path,
            expires: new Date(cookieHeader.expire * 1000),
            httpOnly: cookieHeader.http_only,
          });
          console.log(cookies.get(cookieHeader.cookie_name),"login cookie");
          const { ...rest } = res.data.data.attributes;
          dispatch(setAuthenticationStatus(true));
          dispatch(setCurrentUser(parseUserInfo(rest)));
          setLocalStorage("token", res.headers.token);
          setDataInLocalStorage(res);
          setLocalStorage("profilePic", getProfileImage(res.data.data));
          setLocalStorage("user", res.data.data.attributes["display_name"]);
          if (type === "login") {
            dispatch(setAuthenticationStatus(true));
            if (contestId) {
              history.push(`${CONTEST}/${contestId}`);
            } else {
              history.push(CONTESTS);
            }
          } else {
            setCurrentView(SCREENS.CHANGE_PASSWORD);
          }
        } else if (res.status >= 400) {
          setInvalidOtp(true);
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setInvalidOtp(true);
        setLoading(false);
        Toast.Error(e.data.message);
      });
  };

  const resendOtp = () => {
    if (resendOtpActive) {
      setLoading(true);
      setResendOtpActive(false);
      const payload = {
        email: values.email,
      };
      api
        .requestOtp(payload)
        .then((res) => {
          if (res.status === 200) {
            setRestartTimer(true);
            setResendOtpActive(false);
            Toast.Success(res.data.message);
          } else if (res.status >= 400) {
            Toast.Error(res.data.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          Toast.Error(e.data.message);
        });
    }
  };

  const parseUserInfo = (response) => {
    return {
      userId: response["ID"],
      userStage: response["user_stage"],
      userRole: response["role"],
      displayName: response["display_name"],
      firstName: response["first_name"],
      lastName: response["last_name"],
      phoneExt: response["phoneExt"],
      phoneNumber: response["phone"],
      altPhoneNumber: response["altPhone"],
      emailAddress: response["user_email"],
      login: response["user_login"],
      inviteSource: response["invite_source"],
    };
  };

  const onPasswordLoginClick = () => {
    const payload = {
      email: values.email,
      type: "password",
      password: values.password,
    };
    api
      .login(payload)
      .then((res) => {
        if (res.status === 200) {
          const headers = res.headers;
          const { wp_auth } = headers;
          const cookieHeader = JSON.parse(wp_auth);
          const cookies = new Cookies();
          cookies.set(cookieHeader.cookie_name, cookieHeader.value, {
            domain: cookieHeader.cookie_domain,
            path: cookieHeader.cookie_path,
            expires: new Date(cookieHeader.expire * 1000),
            httpOnly: cookieHeader.http_only,
          });
          setLoading(false);
          const { ...rest } = res.data.data.attributes;
          dispatch(setAuthenticationStatus(true));
          dispatch(setCurrentUser(parseUserInfo(rest)));
          setLocalStorage("token", res.headers.token);
          setDataInLocalStorage(res);
          setLocalStorage("profilePic", getProfileImage(res.data.data));
          if (contestId) {
            history.push(`${CONTEST}/${contestId}`);
          } else {
            history.push(CONTESTS);
          }
        } else if (res.status >= 400) {
          setLoading(false);
          Toast.Error(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, " error");
      });
  };

  const onForgotPasswordSendOtpClick = () => {
    const payload = {
      email: values.email,
    };
    api
      .requestOtp(payload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setCurrentView(SCREENS.FORGOT_PASSWORD_OTP);
        } else if (res.status >= 400) {
          setLoading(false);
          Toast.Error(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "error");
      });
  };

  const onEnterPasswordClick = () => {
    setCurrentView(SCREENS.ENTER_PASSWORD);
  };

  const onForgotPasswordClick = () => {
    setCurrentView(SCREENS.FORGOT_PASSWORD);
  };

  const onRememberPasswordClick = () => {
    setCurrentView(SCREENS.LOGIN);
  };

  const SocialLogin = () => {
    const GOOGLE_LOGIN_CLENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLENT_ID;

    const onSuccess = (response) => {
      const { profileObj } = response;
      const { email } = profileObj;
      const payload = {
        email,
        type: "otp",
      };
      setSocialError(null);
      setSocialLoading(true);
      api
        .login(payload)
        .then((res) => {
          if (res.status === 200) {
            setSocialLoading(false);
            const { ...rest } = res.data.data.attributes;
            dispatch(setAuthenticationStatus(true));
            dispatch(setCurrentUser(parseUserInfo(rest)));
            setLocalStorage("token", res.headers.token);
            setDataInLocalStorage(res);
            setLocalStorage("profilePic", getProfileImage(res.data.data));
            if (contestId) {
              history.push(`${CONTEST}/${contestId}`);
            } else {
              history.push(CONTESTS);
            }
          } else if (res.status >= 400) {
            setSocialLoading(false);
            Toast.Error(res.data.message);
          }
        })
        .catch((e) => {
          setSocialLoading(false);
          console.log(e, " error");
        });
    };

    const onFailure = (response) => {
      console.log(response);
    };

    return (
      <div className="social-buttons-container">
        <div className="horizontal-divider">
          <p className="divider"></p>
          <p>&emsp;Or&emsp;</p>
          <p className="divider"></p>
        </div>
        <GoogleLogin
          clientId={GOOGLE_LOGIN_CLENT_ID}
          render={(renderProps) => (
            <LoadingButton
              className="social-button"
              variant="outlined"
              sx={{ marginTop: "2rem" }}
              onClick={() => {
                renderProps.onClick();
                setSocialError(null);
              }}
              loading={socialLoading}
              disabled={socialLoading}
            >
              <img src={google} alt="Google" /> &nbsp; Continue with Google
            </LoadingButton>
          )}
          buttonText="Continue with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
        />
        <Button
          className="social-button"
          disabled={false}
          variant="outlined"
          startIcon={<img src={linkedin} alt="linkedin" />}
        >
          Continue With LinkedIn
        </Button>
        <p className="otp-error-label">{socialError}</p>
      </div>
    );
  };

  const loginView = () => {
    return (
      <div className="login-form">
        <p className="login-welcome">{STRINGS.LOGIN.WELCOME}</p>
        <p className="login-sign-in-text">
          {STRINGS.LOGIN.LOGIN_WITH_YOUR_ACCOUNT}
        </p>
        <div></div>
        <p className="login-input-label">{STRINGS.LOGIN.ENTER_EMAIL_ID}</p>
        <OutlinedInput
          className="login-input"
          type="email"
          value={values.email}
          placeholder="Email ID"
          onChange={handleChange("email")}
          error={isInvalidEmail}
          autoComplete=""
          disabled={loading}
          endAdornment={
            <InputAdornment position="end">
              <img src={userIcon} alt="User" />
            </InputAdornment>
          }
        />
        {isInvalidEmail && (
          <p className="login-error-label">{STRINGS.LOGIN.INVALID_EMAIL}</p>
        )}
        <LoadingButton
          className="sendOtp-button"
          variant="contained"
          disabled={values.email === "" || isInvalidEmail}
          sx={{ bgcolor: "#4D6CD9", marginTop: "2rem" }}
          onClick={onSendOTP}
          loading={loading}
        >
          {STRINGS.LOGIN.SEND_OTP}
        </LoadingButton>
        <button
          className="enter-password"
          disabled={values.email === "" || isInvalidEmail}
          onClick={onEnterPasswordClick}
        >
          {STRINGS.LOGIN.OR_ENTER_PASSWORD}
        </button>
        {/* {SocialLogin()*/}
        {/* {SignUpNow()}  */}
        {CopyRight()}
      </div>
    );
  };

  const otpInputView = (type = "login") => {
    return (
      <div className="login-form">
        <div className="back-button" onClick={backClicked}>
          <div>
            <div className="arrow arrow-left"></div>&nbsp;{STRINGS.LOGIN.BACK}
          </div>
        </div>
        <div className="enter-otp-label">{STRINGS.LOGIN.ENTER_OTP}</div>
        <div className="otp-info">{STRINGS.LOGIN.OTP_INFO}</div>
        <OtpInput
          value={otp}
          onChange={handleOtpInput}
          shouldAutoFocus={true}
          numInputs={4}
          isInputNum={true}
          inputStyle={"otpInputContainer"}
          hasErrored={invalidOtp}
          errorStyle={"otpInputContainer-error"}
        />
        {invalidOtp && (
          <p className="otp-error-label">{STRINGS.LOGIN.OTP_INVALID}</p>
        )}
        <LoadingButton
          className="login-button"
          variant="contained"
          sx={{ bgcolor: "#4D6CD9", marginBottom: "14rem" }}
          disabled={!validateOtpInput(otp)}
          onClick={() => onOtpLoginClick(type)}
          loading={loading}
        >
          {STRINGS.LOGIN.LOGIN}
        </LoadingButton>
        <div className="resend-otp-label">
          <div
            className={
              resendOtpActive
                ? "resend-otp-label-active"
                : "resend-otp-label-inactive"
            }
            onClick={resendOtp}
          >
            {STRINGS.LOGIN.RESEND_OTP}
          </div>
          <div className="otp-timer">
            &nbsp;(00:
            {
              <Timer
                seconds={"59"}
                restartTimer={restartTimer}
                onEndTimer={() => {
                  setResendOtpActive(true);
                }}
                changeReset={() => setRestartTimer(false)}
              />
            }
            )
          </div>
        </div>
         {SignUpNow()}
        {CopyRight()}
      </div>
    );
  };

  const passwordInputView = () => {
    return (
      <div className="login-form">
        <div className="back-button" onClick={backClicked}>
          <div>
            <div className="arrow arrow-left"></div>&nbsp;{STRINGS.LOGIN.BACK}
          </div>
        </div>
        <div className="enter-password-label">
          {STRINGS.LOGIN.ENTER_PASSWORD}
        </div>
        <div className="otp-info">{STRINGS.LOGIN.ENTER_PASSWORD_DESC}</div>
        <OutlinedInput
          className="password-input"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          autoComplete=""
          placeholder="Enter Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? (
                  <img src={eyeOpenIcon} alt="Show" />
                ) : (
                  <img src={eyeClosedIcon} alt="Hide" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <LoadingButton
          className="login-button-password"
          variant="contained"
          sx={{
            bgcolor: "#4D6CD9",
            marginBottom: "6rem",
            marginTop: "2rem",
          }}
          disabled={!values.password}
          onClick={onPasswordLoginClick}
          loading={loading}
        >
          {STRINGS.LOGIN.LOGIN_NOW}
        </LoadingButton>
        <div onClick={onForgotPasswordClick}>
          <p className="forgot-password">{STRINGS.LOGIN.FORGOT_PASSWORD}</p>
        </div>
         {SignUpNow()} 
        {CopyRight()}
      </div>
    );
  };

  const forgotPasswordView = () => {
    return (
      <div className="login-form">
        <div className="back-button" onClick={backClicked}>
          <div>
            <div className="arrow arrow-left"></div>&nbsp;{STRINGS.LOGIN.BACK}
          </div>
        </div>
        <div className="enter-password-label">
          {STRINGS.LOGIN.RECOVER_PASSWORD}
        </div>
        <div className="otp-info">{STRINGS.LOGIN.RECOVER_PASSWORD_DESC}</div>
        <OutlinedInput
          className="login-input"
          type={"email"}
          value={values.email}
          onChange={handleChange("email")}
          autoComplete=""
          placeholder="Enter email"
        />
        <LoadingButton
          className="login-button-password"
          variant="contained"
          sx={{
            bgcolor: "#4D6CD9",
            marginBottom: "2rem",
            marginTop: "1rem",
          }}
          disabled={!values.email || isInvalidEmail}
          onClick={onForgotPasswordSendOtpClick}
          loading={loading}
        >
          {STRINGS.LOGIN.SEND_OTP}
        </LoadingButton>
        <div onClick={onRememberPasswordClick}>
          <p className="forgot-password">{STRINGS.LOGIN.REMEMBER_PASSWORD}</p>
        </div>
        {CopyRight()}
      </div>
    );
  };

  const changePasswordView = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
        <div
          className="back-button"
          // onClick={() => navigateTo("otp")}
        >
          <div>
            <div className="arrow arrow-left"></div>&nbsp;Back
          </div>
        </div>
        <div className="create-password">Create Password</div>

        <input
          name="password"
          type="password"
          placeholder="Enter Password"
          {...register("password")}
          className={`password-input ${errors.password ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback">{errors.password?.message}</div>
        <input
          name="confirmPassword"
          type="password"
          placeholder="Re-enter Password"
          {...register("confirmPassword")}
          className={`password-input ${
            errors.confirmPassword ? "is-invalid" : ""
          }`}
        />
        <div className="invalid-feedback">
          {errors.confirmPassword?.message}
        </div>

        <button type="submit" className="create-password-proceed">
          Register
        </button>
      </form>
    );
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const payload = {
      password: data.password,
      email: values.email,
    };
    setLoading(false);
    api
      .resetPassword(payload)
      .then((res) => {
        if (res.status === 200) {
          setLocalStorage("token", res.headers.token);
          dispatch(setAuthenticationStatus(true));

          if (contestId) {
            history.push(`${CONTEST}/${contestId}`);
          } else {
            history.push(CONTESTS);
          }
        } else if (res.status >= 400) {
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        Toast.Error(e.data.message);
      });
    return false;
  }

  function renderSwitch(currentView) {
    switch (currentView) {
      case SCREENS.LOGIN:
        return loginView();
      case SCREENS.OTP:
        return otpInputView();
      case SCREENS.ENTER_PASSWORD:
        return passwordInputView();
      case SCREENS.FORGOT_PASSWORD:
        return forgotPasswordView();
      case SCREENS.FORGOT_PASSWORD_OTP:
        return otpInputView("forgot-password");
      case SCREENS.CHANGE_PASSWORD:
        return changePasswordView();
      default:
        return loginView();
    }
  }

  return (
    <ErrorBoundary>
      <div className="login-wrapper card-container-height">
        <Card>
          <div className="login-container">
            <img src={loginImage} alt="Login" />
            {renderSwitch(currentView)}
          </div>
        </Card>
      </div>
    </ErrorBoundary>
  );
};

export default Login;
