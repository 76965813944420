import { useState } from "react";
import Card from "../Card";
import wantedImage from "../../assets/wanted.svg";
import "./styles.css";
import StyledCheckbox from "../Checkbox";
import {JSDetails} from "../../types/hotlist";

interface Props {
  jsDetails: JSDetails,
  checkedList: string[],
  setSelectedJobIds: (row: any, event: React.ChangeEvent<HTMLInputElement>) => void
}

const ContestCard: React.FC<Props> = (props) => {
  const { jsDetails , checkedList, setSelectedJobIds } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`hotlist-card-wrapper ${
        hovered ? "hotlist-card-wrapper-hovered" : ""
      }`}
    >
      <Card>
        <div
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
          onTouchStart={() => {
            setHovered(true);
          }}
          onTouchEnd={() => {
            setHovered(false);
          }}
          className="hotlist-card-container"
        >
          <p className="hotlist-card-id">{`ID - ${jsDetails.id}`}</p>
          <p>{`Uploaded On ${jsDetails.uploadedOn}`}</p>
          <img
            src={wantedImage}
            alt="Job Seeker"
            className="hotlist-card-wanted-image"
          />
          <p className="hotlist-card-role">{jsDetails.role}</p>
          <hr className="hotlist-card-separator" />
          <div className="hotlist-card-details-container">
            <div className="hotlist-card-details">
              <p>Primary Skill :</p>
              <p>
                <b>{jsDetails.primarySkill}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Experience :</p>
              <p>
                <b>{jsDetails.experience}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Expected CTC :</p>
              <p>
                <b>{jsDetails.expectedCTC}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Current Location :</p>
              <p>
                <b>{jsDetails.currentLocation}</b>
              </p>
            </div>
          </div>
          <hr className="hotlist-card-separator" />
          <div className="hotlist-card-hovered-container">
            <p className="hotlist-card-hovered-text">Earliest Joining Date :</p>
            <p>
              <b>{jsDetails.joiningDate}</b>
            </p>
            <p className="hotlist-card-hovered-text">Preferred Locations :</p>
            <p>
              <b>{jsDetails.preferredLocations}</b>
            </p>
          </div>
          {/* <Button
            className="hotlist-card-connect"
            label="Connect"
            onClick={handleConnect}
          /> */}
        </div>
      </Card>
    </div>
  );
};

export default ContestCard;
