import Button from "../Button";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../utils/storage-manager";
import { sendPostRequest, linksRequest } from "./Api";

const TermsToUse = ({ onActiveFormChange }) => {
  const [accepted, setAccepted] = useState(false);
  let history = useHistory();
  const backHandler = () => {
    onActiveFormChange("Verification");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // onActiveFormChange('InviteStaff');
    if (!accepted) {
      alert("Please accept the form to continue");
    } else {
      const id = getLocalStorage("id");
      const companyName = getLocalStorage("companyName");
      const formData = {
        terms_and_conditions: true,
        recruiter_type: "company",
        company_name: companyName,
        id: id,
      };

      sendPostRequest(`${id}/company/agreement`, formData);
      const dashboardUrl = getLocalStorage("dashboardUrl");
      window.location.href = dashboardUrl;
    }
  };

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };

  return (
    <>
      <div>
        <h1>Terms to use & Privacy policies</h1>
        <div style={{ display: "flex" }}>
          <textarea
            style={{
              flex: 1,
              overflow: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              marginRight: "20px",
              marginTop: "20px",
              marginBottom: "50px",
              height: "400px",
            }}
            readOnly
          >
            A GENERAL TERMS This Terms of Use (“Terms”) is drafted in conformity
            with the Information Technology (Intermediaries Guidelines) Rules,
            2011 specified under the Information Technology Act, 2000 in order
            to regulate the terms and conditions of usage and access to the
            Website (defined under) and the Services (defined hereinafter). No
            physical or digital signature is necessitated in order to validate
            this Agreement. M/s. Spoorthi Hires Consulting Services Private
            Limited, a company registered under the Companies Act, 2013 and
            having its registered office at #Flat-409, Block-C1, Hemadurga
            Towers, Behind Sitara Grand Hotel, Miyapur, Hyderabad 500049
            (hereinafter referred to as “Company”, “we”, “us” or “our”) owns and
            controls the Website, www.hiringhood.com (hereinafter referred to as
            “Website”) and is in the business of providing an aggregation
            platform for recruitment vendors who source job seekers to employers
            for their accelerated hiring demands (hereinafter referred to as
            “Services”). For the purpose of these Terms of Use, wherever the
            context so requires “You” or “Your” or “User” shall mean the
            recruiters who visit our Website. For the sake of clarity “User”
            shall include without limitation Registered User and Visitors. Your
            use of the Website is governed by the following terms and
            conditions, which are constantly administered at regular intervals.
            B. INTRODUCTION AND ACCEPTANCE 1. Your use/access of the Website are
            subject to the terms of our Website and other policies as mentioned
            in our Website. By contacting us for the Services or availing the
            Services or by registering with us or visiting our Website, now or
            in the future, you signify that you agree to these Terms of Use
            (“Terms”) and our Policies placed in the Website. 2. The Terms
            applies to every user who uses our Services or accesses/visits the
            Website. It also applies to any legal entity which may be
            represented by you under actual or apparent authority. In case of a
            entity, the officer who is incharge of the operations of the entity
            shall be liable / responsible for the acts/omission of the entity or
            its employees or other individual users who are part of the entity.
            3. The Terms are subject to modifications, at the sole and absolute
            discretion of the Company. Further, the Company may from time to
            time formulate new Terms. The most current version of the Terms will
            supersede all previous versions. You understand that Your use of the
            Website after any changes to the Terms or addition of new policies
            or Terms constitutes Your specific acceptance and it is to be bound
            by the most recent version of the Terms. C. CONSENT OF THE USERS 1.
            You hereby agree that You shall use the Website for the purpose
            specified in these Terms of Use and shall not use the Website for
            any unauthorized and unlawful purpose, including impersonating
            another person and shall not attempt to reverse-engineer, alter or
            modify any part of the /Website. 2. You acknowledge that if the
            Company finds You to be in violation of any of the applicable
            laws/rules/ regulations/guidelines set out by the authorities, then
            the Company shall be entitled to terminate Your account, ban You
            from accessing the Website or take such other legal action as may be
            required. D. WEBSITE CONTENT 1. The Company does not guarantee that
            the Website is well-suited to Your device or that the contents of
            the Website are accurate, correct and free of viruses. 2. All
            Contents, programs, services, processes, designs, software,
            technologies, trademark, trade names, inventions and materials made
            by the Company or for the Company and are posted on the Website are
            wholly owned by the Company. The copyright to content on the Website
            as well as the intellectual product contained thereon vests wholly
            and completely in the Company. 3. The Company cannot and does not
            guarantee or warrant that files available for downloading through
            the Website will be free of infection by software viruses or other
            harmful computer code, files or programs. 4. The contents of the
            Website, such as text, graphics, images and other material licensed
            by the Company (“Content”) are protected by copyright under Indian
            laws, and title to the Content shall not pass to You or any other
            User. Unauthorized use of the Content may violate copyright,
            trademark, and other laws. None of the Content may be
            reverse-engineered, disassembled, decompiled, reproduced,
            transcribed, stored in a retrieval system, translated into any
            language or computer language, re-transmitted in any form or by any
            means (electronic, mechanical, photo-reproduction, recordation or
            otherwise), resold or redistributed. E. INDEMNITY: 1. You, hereby,
            agree to indemnify and hold harmless the Company from and against
            any and all claims, charges, demands, damages, liabilities, losses,
            expenses, and liabilities of whatever nature and howsoever arising
            (including but not limited to any legal or other professional fees
            and the costs of defending or prosecuting any claim and any loss of
            profit, goodwill, and any other direct or consequential loss)
            incurred or suffered by the Company directly or indirectly by reason
            including but not limited to the following: ● Your use/misuse of or
            access to the Website; ● Using Your User id and password; ● any
            activity related to Your internet account (including negligent or
            wrongful conduct) by You or any other person accessing the Website
            using Your internet account; ● from any transaction that You conduct
            as a result of the contact facilitated by the Website; or ● from
            Your infringement of any intellectual product rights in any posting
            to or transmission via the Website; ● You shall agree to defend the
            Company, directors, managers, associates, partners, assignees,
            licensees, affiliates and agents in the event of any loss and
            damages caused to anyone, due to actions caused by You, or by anyone
            on Your behalf; ● The Company or any of its affiliates is not
            responsible for any legal proceedings, which are initiated by either
            of the parties to this Agreement. In case the Company gets involved
            in any of the proceedings by either of the parties, the Company
            shall recover the costs and damages which are incurred by it, from
            the party which names or involves the Company to the proceedings. ●
            None of the information available on the Website is intended to be a
            substitute for independent professional advice and Users are
            recommended to seek advice from suitably qualified professionals if
            relevant to their particular circumstances. F. RESTRICTIONS ON
            USERS: The Users shall ensure the following: ● To not impede and
            impair the operations and the functionality of the Website. 2 ● To
            be compliant with the applicable laws and regulations. ● To not
            access any data, that is not meant to be used by the User. ● To
            breach any of the security measures without appropriate
            authorization. ● To copy and distribute the content and information
            provided on this Website, on other servers with due authorization
            from the Company. ● The Users undertake to not send any information
            on behalf of the Company. It is solely the responsibility of the
            Website to solicit any information and to send through emails,
            SMS’s, multimedia content and advertisements on the World Wide Web
            to its Users or prospective Users. ● The Users are prohibited from
            passing on any information, which contains confidential information
            about the other Users, to any third parties or other servers,
            without prior consent from the Company. ● Violates any law, which is
            in force for the time being. ● Any message or information which is
            used to deceive the other Users. G. CONFIDENTIALITY OF INFORMATION
            You shall not, either during the period of use of the Website or
            thereafter, except, in the proper course of Your duties, divulge to
            any person whomsoever and shall use Your best efforts to prevent the
            unauthorized use, dissemination, publication or disclosure of any
            confidential information. You shall not either during the period of
            use of Website or thereafter use the confidential information for
            Your own benefit, or for the benefit of a third party, and will not
            permit such use, except with the prior, express written consent of
            the Company. H. DISCLAIMERS AND WARRANTIES 1. You acknowledge and
            agree that we are only an intermediary between the Users and that we
            cannot be a party to or control in any manner any
            transaction/litigation between the Users. We advise the Users to
            always conduct their background checks and believe in their
            rationality while engaging with each other. It may be also noted
            that we do not endorse or promote any Users to anyone making them
            believe to engage with each other. 2. We do not make any
            representation or warranty with regard to any Users. You are advised
            to independently use your best judgment in that behalf. 3. We are
            not responsible for any non-performance or breach of any contract
            entered into between Users. We cannot and do not guarantee that the
            concerned User will perform any transaction concluded on the
            Application/Website. We shall not and are not required to mediate or
            resolve any dispute or disagreement between Users. We do not make
            any representation or warranty as to the performance and conduct of
            the Users. In particular, we do not implicitly or explicitly support
            or endorse the Users to each other. We accept no liability for any
            errors or omissions, of any manner on behalf of the Users. 4. All
            the opinions, views, expressions and comments expressed by the Users
            are their own and there is no direct or indirect endorsement by the
            Company. However, the Company makes every attempt to detect content
            that is malicious, fraudulent, offensive and abusive. However, if
            any content is found to be offensive, it can be reported by You
            under the option of report abuse. 5. No information which is put on
            the Website is to be accepted as an invitation to an offer, made by
            the platform. There is no endorsement which is made by the Company
            through its Website. 6. There are no express representations and
            warranties, whether express or implied, made by the Company
            regarding the accuracy, reliability and/or quality of any
            information transmitted and/or obtained through the use of the
            Services of the Company. 3 7. The Company assumes no responsibility
            for any damages or viruses that may infect Your computer equipment
            or other product on account of Your access to, use of, or browsing
            in this site. 8. We disclaim responsibility for any harm to persons
            resulting from any Services referred to in the Website. The Company
            is not associated with any Users. I. LIMITATION OF LIABILITY: 1. All
            information included on or otherwise made available to You through
            the Website are provided by the Company on an as is and as available
            basis, either expressed or implied and such information is not to be
            intended as a medium for verifying the credentials, qualifications,
            or abilities of any professional contained therein., We specifically
            disclaim warranties of any kind to the extent allowed by the
            applicable law. You expressly agree that Your use of the Website at
            Your sole risk. 2. The Company assumes no responsibility for any
            damages or viruses that may infect Your computer equipment or other
            property on account of Your access to, use of, or browsing in this
            site. 3. The Company has exerted reasonable efforts to ensure that
            all information published on the Website is accurate at the time of
            posting; however, there may be errors in such information for which
            we shall have no liability. We reserve the right to remove or alter
            any of the information contained on the Website at our sole
            discretion. 4. The Company cannot guarantee the adequacy, currency
            or completeness of the Website content. The Company does not warrant
            or endorse the effectiveness, quality or safety of the products
            available on its Website. 5. We do not exercise any ownership,
            control or supervision over the Users or their services/conducts.
            The Company makes no representation or provides no warranty and
            guarantee (express or implied) for the conducts/performance of the
            Users, even if they have been connected through the Website or the
            Company. Any claims, actions or inactions relating to the above
            should be settled inter-se between the Users. Nothing is regulated
            or evaluated by the Company administration. The Company only acts as
            an aggregator and connects the Users. 6. Under no circumstances will
            the Company be liable to you or your representative for any direct,
            indirect, incidental, special, punitive or consequential damages of
            any character that arise from your use of any of the Services
            provided by the Company or conduct of the Users or any agent,
            employee, consultant or partner of the User, as applicable. The
            liability of the Company, in any event, shall not exceed the total
            fees paid by you for availing the Services. By engaging our
            Services, you acknowledge and agree to solely assume the risk of
            these limitations. 7. The Service may entail links to third party
            websites / applications that are neither controlled nor owned by the
            Company. The Company has no control over the content on such
            websites and therefore devolves itself of all the responsibilities
            for the text, software, scripts, graphics, photos, sounds, music,
            videos, audiovisual combinations, interactive features and
            miscellaneous materials you may come across / access through, or
            contribute to the service and the privacy policies, terms of service
            or practices of any third party websites / applications. Further,
            the Company is not authorized to edit any third party’s content. By
            using the Service, you expressly devolve the Company of all its
            liability arising from your usage of any third-party website /
            application. 4 8. We may let You view our information and
            communicate with us through the social media services such as
            Facebook and Twitter. The Company explicitly disclaims any
            responsibility for the terms of use and privacy policies that govern
            these third-party Websites, which are in no way associated with us.
            9. The Company reserves the right to modify or withdraw any part of
            the Website or any of its content at any time without notice. J.
            CONDUCT OF RECRUITMENT AGENCY The Recruitment agencies (“Agencies”),
            with a representation that they have a pool of potential
            job-seekers, whom they would like to source to Hiringhood platform
            to be onboarded in the office of Employers, they shall adhere by all
            the terms of this Terms of Use along with the terms as mentioned
            below under this section. 1. The Agencies may be either a corporate
            entity, firms, society or individual and these Terms of Use shall be
            applicable to all, irrespective of the fact that they are registered
            or not. 2. The Agencies shall refer potential job-seekers to the
            Company pursuant to the requirements as may be mentioned by the
            Company in the Website or communicated by the Company to them. 3.
            The Agencies shall exercise due diligence in the selection of
            jobseekers and must ensure that jobseekers have the necessary
            competence (hard skills and soft skills) that are in line with the
            requirements. 4. The Agencies shall be deemed to be the owner of the
            profile of the jobseeker provided to the Company for a period of 3
            months of intimating the Company of such jobseeker’s profile.
            Further, the Agencies shall be solely liable for the acts/omission
            of the jobseeker for the duration mentioned in the empanelment
            agreement signed, from the date on which the jobseeker is onboarded
            in the office of the Employer. 5. In the event that the jobseeker
            resigns or is terminated by the Employer due to acts/omissions
            attributable to the jobseeker, the Agencies shall be liable to refer
            another jobseeker within 30 days from the date of intimation by the
            Company to the Agencies, without any additional charges/costs. K.
            MISCELLANEOUS 1. Any violation of this Term or any indecent action
            towards other Users will not be tolerated and shall attract prompt
            and punitive disciplinary action as per applicable laws enforced in
            India and including but not limited to termination of usage rights
            as well as legal action. In the event of termination, Users will not
            receive any credit or payment from the Company. 2. Without limiting
            the Company to other remedies, You must pay the Company all fees
            owed to Us and reimburse the Company for all losses and costs
            (including any and all the Company associate, officers or
            representative’s time) and reasonable expenses (including but not
            limited to legal fees) related to investigating such breach and
            collecting such fees. 3. The content may contain typographical
            errors or other errors or inaccuracies and may not be complete or
            current. The Company, therefore, reserves the right to correct any
            errors, inaccuracies or omissions and to change or update the
            content at any time without prior notice. The Company does not,
            however, guarantee that any errors, inaccuracies or omissions will
            be corrected. 4. You shall not assign any rights or obligations
            arising under this Term, whether by operation of law or otherwise,
            without the prior written consent of the Company. 5 5. Any claim or
            controversy that arises out of or relates to this agreement, or the
            breach of it, the same shall be referred to a person nominated by
            the Company whose decision shall be final and binding upon the
            parties hereto. Such references shall be deemed to be a submission
            to arbitration in accordance with the provisions of the Arbitration
            and Conciliation Act, 1996. The seat of arbitration shall be in
            Hyderabad, India and the process shall be in English Language. 6.
            Notwithstanding the foregoing, if You breach or threaten to breach
            any provision of this Agreement You acknowledge that irreparable
            harm will result to the Company if You breach any obligation under
            this Agreement and You acknowledge and agree that such a breach
            would not be properly compensable by an award of damages.
            Accordingly, You agree that the Company shall be entitled to, in
            addition to other available remedies and damages, injunctive relief
            or other equitable relief enjoining such breach at the earliest
            possible date. 7. In the event that any provision of this Agreement
            is held to be invalid or unenforceable by a court or regulator of
            competent jurisdiction, the valid or enforceable portion thereof and
            the remaining provisions of this Agreement will remain in full force
            and effect. The Company’s failure to exercise or enforce any right
            or provision of these Terms shall not constitute a waiver of such
            right or provision. Any waiver or failure to enforce any provision
            of this Agreement on one occasion will not be deemed a waiver of any
            other provision or of such provision on any other occasion. All
            waivers must be in writing. 8. This Agreement shall be governed by
            the laws of India and in case of any dispute between You and the
            Company, in relation to the Services, the Courts of Hyderabad, India
            shall have exclusive jurisdiction. L. GRIEVANCE OFFICER: In
            accordance with the Information Technology Act 2000 and Information
            Technology (Intermediaries Guidelines) Rules, 2011 made there under,
            the contact details of the Grievance Officer are provided below in
            order to contact him when You have some problem or grievance with
            respect to the Services of the Application/Website. You should
            promptly raise such grievance or complaint with the below designated
            Grievance Officer to enable him to resolve the complaint Grievance
            Officer: Mr. Kanaka Bhaskar Mamillapalli Email:
            bhaskar.mamillapalli@hiringhood.com
          </textarea>

          <div
            style={{
              position: "fixed",
              bottom: "20%",
              left: "45%",
              color: "black",
            }}
          >
            <label>
              <input
                type="checkbox"
                checked={accepted}
                onChange={handleCheckboxChange}
              />
              <p style={{ display: "inline", marginLeft: "5px" }}>
                I have verified Content and <b>Accept</b> all the terms and
                conditions in it.
              </p>
            </label>
          </div>
        </div>

        <div className="nextBtn">
          <Button
            label="Done"
            variant="secondary"
            style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
            type="submit"
            onClick={handleSubmit}
          />
        </div>
        <div className="backBtn">
          <Button
            label="Back"
            variant="secondary"
            style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
            type="Back"
            onClick={backHandler}
          />
        </div>
      </div>
    </>
  );
};

export default TermsToUse;
