import { useState } from "react";
import Button from "../Button";
import Card from "../Card";
import SliderFilter from "../SliderFilter";
import CheckboxFilter from "../CheckboxFilter";
import filterImage from "../../assets/filter.svg";
import styles from "./styles.module.scss";

const experienceMarks = [
  {
    value: 0,
    label: "0 Yrs",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50 Yrs",
  },
];

const ContestFilters = (props) => {
  const { contestsData, onFiltersChanged, onReset } = props;
  const contests = contestsData?.contests;
  const [locationContests, setLocationContests] = useState(contests);
  const [employmentTypeContests, setEmploymentTypeContests] = useState(
    contests
  );
  const [jobRoleContests, setJobRoleContests] = useState(contests);
  const [employerContests, setEmployerContests] = useState(contests);
  const [experienceContests, setExperienceContests] = useState(contests);

  const hasObject = (array, object) => {
    return array.filter((item) => item.id === object.id).length > 0;
  };

  const getCommonContests = (
    locationContests,
    employmentTypeContests,
    jobRoleContests,
    employerContests,
    experienceContests
  ) => {
    const commonContests = [];
    const clonedContests = JSON.parse(JSON.stringify(contests));
    clonedContests.forEach((contest) => {
      if (
        hasObject(locationContests, contest) &&
        hasObject(employmentTypeContests, contest) &&
        hasObject(jobRoleContests, contest) &&
        hasObject(employerContests, contest) &&
        hasObject(experienceContests, contest)
      ) {
        commonContests.push(contest);
      }
    });

    onFiltersChanged(commonContests);
  };

  const onLocationsChange = (list) => {
    setLocationContests([...list]);
    setLocationContests((state) => {
      getCommonContests(
        state,
        employmentTypeContests,
        jobRoleContests,
        employerContests,
        experienceContests
      );
      return state;
    });
  };

  const onEmploymentTypeChange = (list) => {
    setEmploymentTypeContests([...list]);
    setEmploymentTypeContests((state) => {
      getCommonContests(
        locationContests,
        state,
        jobRoleContests,
        employerContests,
        experienceContests
      );
      return state;
    });
  };

  const onJobRoleChange = (list) => {
    setJobRoleContests([...list]);
    setJobRoleContests((state) => {
      getCommonContests(
        locationContests,
        employmentTypeContests,
        state,
        employerContests,
        experienceContests
      );
      return state;
    });
  };

  const onEmployerChange = (list) => {
    setEmployerContests([...list]);
    setEmployerContests((state) => {
      getCommonContests(
        locationContests,
        employmentTypeContests,
        jobRoleContests,
        state,
        experienceContests
      );
      return state;
    });
  };

  const onExperienceChange = (list) => {
    setExperienceContests([...list]);
    setExperienceContests((state) => {
      getCommonContests(
        locationContests,
        employmentTypeContests,
        jobRoleContests,
        employerContests,
        state
      );
      return state;
    });
  };

  return (
    <Card>
      <div className={styles["contest-filters-container"]}>
        <div className={styles["contest-filters-title-container"]}>
          <img src={filterImage} />
          <p className={styles["contest-filters-title"]}>Filter Search</p>
        </div>
        <Button
          className={styles["contest-filters-clear-filters-button"]}
          label="Clear Filters"
          onClick={onReset}
          variant="outlined"
        />
        <hr className={styles["contest-filters-separator"]} />
        <CheckboxFilter
          filterName="Contest Type"
          filters={contestsData.allEmploymentTypes}
          field="filterEmploymentType"
          list={contests}
          onFilterChanged={onEmploymentTypeChange}
          isExpanded
        />
        <hr className={styles["contest-filters-separator"]} />
        <SliderFilter
          filterName="Experience Range"
          marks={experienceMarks}
          min={0}
          max={50}
          step={1}
          range={[0, 50]}
          list={contests}
          field="filterExperience"
          onFilterChanged={onExperienceChange}
          isExpanded
        />
        <hr className={styles["contest-filters-separator"]} />
        <CheckboxFilter
          filterName="Employer"
          filters={contestsData.allEmployers}
          field="filterEmployer"
          list={contests}
          onFilterChanged={onEmployerChange}
        />
        <hr className={styles["contest-filters-separator"]} />
        <CheckboxFilter
          filterName="Job Role"
          filters={contestsData.allJobRoles}
          field="filterJobRole"
          list={contests}
          onFilterChanged={onJobRoleChange}
        />
        <hr className={styles["contest-filters-separator"]} />

        <CheckboxFilter
          filterName="Locations"
          filters={contestsData.allLocations}
          field="filterLocations"
          list={contests}
          onFilterChanged={onLocationsChange}
        />
      </div>
    </Card>
  );
};

export default ContestFilters;
