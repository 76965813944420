import { useState } from "react";
import Button from "../Button";
import { sendPostRequest, linksRequest, wpPost,linksRequest_autologin } from "./Api";
import { getLocalStorage } from "../../utils/storage-manager";

const Professional = ({ onActiveFormChange }) => {
  const [form, setForm] = useState({
    companyRegName: "",
    regDetails: "",
    brandName: "",
    websiteUrl: "",
    nameOfAuthorized: "",
    designation: "",
    AuthorizedEmail: "",
    phone: "",
    businessAge: "",
    RegOfcAddress: "",
    CorpOfcAddress: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      vendor_entity_registration_details: form.regDetails,
      rc_brand_name: form.brandName,
      website: form.websiteUrl,
      rc_authorized_signatory: {
        full_name: form.nameOfAuthorized,
        designation: form.designation,
        email: form.AuthorizedEmail,
        phone_number: form.phone,
      },
      company_full_address: {
        line_1: form.RegOfcAddress,
        line_2: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
      },
      company_duration_in_domestic_recruiting: form.businessAge,
      rc_corporate_office_address: {
        line_1: form.CorpOfcAddress,
        line_2: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
      },
    };
    if (Object.keys(errors).length === 0) {
      onActiveFormChange("ExperienceDetails");
      const id = getLocalStorage("id");
      sendPostRequest(`${id}/company/details`, formData);
      linksRequest_autologin(`auto-login`).then((response) => {
        console.log("auto-login",response);
      });
    }
  };

  const errorHandler = () => {
    const newErrors = {};
    const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (form.AuthorizedEmail && !EMAIL_REGEX.test(form.AuthorizedEmail)) {
      newErrors.AuthorizedEmail = "Please enter a valid email address";
    } else {
      delete newErrors.AuthorizedEmail;
    }

    const phoneRegex = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/; // Assuming the phone number should be 10 digits long
    if (form.phone && !phoneRegex.test(form.phone)) {
      newErrors.phone = "Please enter a valid mobile number";
    } else {
      delete newErrors.phone;
    }
    setErrors(newErrors);
  };

  const backHandler = () => {
    onActiveFormChange("personal");
  };

  const skipHandler = () => {
    onActiveFormChange("TermsToUse");
    linksRequest_autologin(`auto-login`).then((response) => {
      console.log("auto-login",response);
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <button className="skip" onClick={skipHandler}>
        Skip{" "}
      </button>

      <div style={{ marginTop: "3%" }}>
        <label className="label">Vendors Entity Registration Details</label>
        <input
          className={`input-field ${errors.regDetails ? "error" : ""}`}
          type="text"
          name="regDetails"
          placeholder="Type Here..."
          value={form.regDetails}
          onChange={handleChange}
        />

        <div style={{ display: "flex", gap: "15%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="label">Brand Name </label>
            <input
              className={`input-field ${errors.brandName ? "error" : ""}`}
              type="text"
              name="brandName"
              placeholder="Type Here..."
              value={form.brandName}
              onChange={handleChange}
              style={{ width: "120%" }}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className="label">Website URL</label>
            <input
              className={`input-field ${errors.websiteUrl ? "error" : ""}`}
              type="text"
              name="websiteUrl"
              placeholder="Type Here..."
              value={form.websiteUrl}
              onChange={handleChange}
              style={{ width: "90%" }}
            />
          </div>
        </div>

        <label className="label">Name of the Authorized Signatory </label>
        <input
          className={`input-field ${errors.nameOfAuthorized ? "error" : ""}`}
          type="text"
          name="nameOfAuthorized"
          placeholder="Type Here..."
          value={form.nameOfAuthorized}
          onChange={handleChange}
        />

        <label className="label">
          Designation of the Authorized Signatory{" "}
        </label>
        <input
          className={`input-field ${errors.designation ? "error" : ""}`}
          type="text"
          name="designation"
          placeholder="Type Here..."
          value={form.designation}
          onChange={handleChange}
        />

        <label className={`label ${errors.AuthorizedEmail ? "error" : ""}`}>
          Email id of the Authorized Signatory
          <nspb />
          {errors.AuthorizedEmail && (
            <span className="error-message">{errors.AuthorizedEmail}</span>
          )}
        </label>
        <input
          className={`input-field ${errors.AuthorizedEmail ? "error" : ""}`}
          type="text"
          name="AuthorizedEmail"
          placeholder="Type Here..."
          value={form.AuthorizedEmail}
          onBlur={errorHandler}
          onChange={handleChange}
        />

        <label className={`label ${errors.phone ? "error" : ""}`}>
          Phone number of the Authorized Signatory
          <nspb />
          {errors.phone && (
            <span className="error-message">{errors.phone}</span>
          )}
        </label>
        <input
          className={`input-field ${errors.phone ? "error" : ""}`}
          type="text"
          inputMode="Numeric"
          name="phone"
          placeholder="Type Here..."
          value={form.phone}
          onBlur={errorHandler}
          onChange={handleChange}
        />
        <div style={{ display: "flex", gap: "15%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="label">Registor Office Address</label>
            <input
              className={`input-field ${errors.RegOfcAddress ? "error" : ""}`}
              type="text"
              name="RegOfcAddress"
              placeholder="Type Here..."
              value={form.RegOfcAddress}
              onChange={handleChange}
              style={{ width: "190%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20%",
            }}
          >
            <label className={`label ${errors.businessAge ? "error" : ""}`}>
              Business Age
              <nspb />
              {errors.businessAge && (
                <span className="error-message">{errors.businessAge}</span>
              )}
            </label>
            <input
              className={`input-field ${errors.businessAge ? "error" : ""}`}
              type="number"
              name="businessAge"
              placeholder="Business Age"
              value={form.businessAge}
              onChange={handleChange}
              style={{ width: "80%" }}
            />
          </div>
        </div>

        <label className="label">Corporate Office Address</label>
        <input
          className={`input-field ${errors.CorpOfcAddress ? "error" : ""}`}
          type="text"
          name="CorpOfcAddress"
          placeholder="Type Here..."
          value={form.CorpOfcAddress}
          onChange={handleChange}
        />
      </div>
      <div className="nextBtn">
        <Button
          label="Next"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="submit"
        />
      </div>
      <div className="backBtn">
        <Button
          label="Back"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="Back"
          onClick={backHandler}
        />
      </div>
    </form>
  );
};
export default Professional;
