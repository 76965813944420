import { useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { CONTEST_DETAILS } from "../../utils/constants/routes";
import Button from "../Button";
import Card from "../Card";
import ContestDetail from "../ContestDetail";
import CashReward from "../CashReward";
import Share from "../Share";
import companyImage from "../../assets/company.svg";
import experienceImage from "../../assets/experience.svg";
import menuImage from "../../assets/menu.svg";
import matchingProfilesImage from "../../assets/matching-profiles.svg";
import quotaImage from "../../assets/quota.svg";
import calendarImage from "../../assets/calendar-background.svg";
import starImage from "../../assets/star.svg";
import viewImage from "../../assets/view.svg";
import thumbsDownImage from "../../assets/thumbs-down.svg";
import bookmarkImage from "../../assets/bookmark.svg";
import helpImage from "../../assets/help.svg";
import { getImageForTag, getImageForBadge } from "../../utils/util";
import "./styles.css";
import { getLocalStorage } from "../../utils/storage-manager";

interface ContestDetails {
  id: string;
  contestCreatedDuration: string;
  employmentType: string; 
  jobTitle: string;
  cashReward: string;
  company: string;
  experience: string;
  tag: string;
  badge: string;
  matchingProfiles: string;
  totalQuota: number;
  daysLeft: number;
  iconsToShow: Array<string>;
  rating?: number;
  views?: number;
}

interface Props {
  contestDetails: ContestDetails;
}

const ContestCard: React.FC<Props> = (props) => {
  const { contestDetails } = props;
  const { iconsToShow = [] } = contestDetails;
  const [hovered, setHovered] = useState(false);
  const tag = getImageForTag(contestDetails.tag);

  const isAuthenticated = useSelector(
    (state: RootStateOrAny) =>
      state.session.authenticated || getLocalStorage("token", null)
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setHovered(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const urlToShare = `www.hiringhood.com/contest/${contestDetails.id}`;

  return (
    <div
      className={`contest-card-wrapper ${
        hovered ? "contest-card-wrapper-hovered" : ""
      }`}
    >
      <Card>
        <div
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
          onTouchStart={() => {
            setHovered(true);
          }}
          onTouchEnd={() => {
            setHovered(false);
          }}
          className={`contest-card-container ${
            hovered ? "contest-card-container-hovered" : ""
          }`}
        >
          <div className="contest-card-job-posted-duration">
            {tag && <img src={tag} alt="Tag" />}
            {/* <p>{contestDetails.contestCreatedDuration}</p> */}
          </div>
          {getImageForBadge(contestDetails.badge) && (
            <img
              src={getImageForBadge(contestDetails.badge)}
              alt="Badge"
              className="contest-card-badge"
            />
          )}
          {iconsToShow.length > 0 && (
            <div className="contest-card-top-icons-container">
              <img
                src={menuImage}
                alt="Menu"
                className="contest-card-menu contest-card-top-icon"
                onClick={handleClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "Menu",
                }}
              >
                <div className="contest-card-icon-conatiner">
                  <img
                    src={starImage}
                    alt="Contest Rating"
                    title="Contest Rating"
                  />
                  <p className="contest-card-icons-text">
                    {contestDetails.rating}
                  </p>
                </div>
                {iconsToShow.includes("visit") && (
                  <div className="contest-card-icon-conatiner">
                    <img src={viewImage} alt="Views" title="Views" />
                    <p className="contest-card-icons-text">
                      {contestDetails.views
                        ? `${contestDetails.views.toLocaleString("en-IN")}`
                        : null}
                    </p>
                  </div>
                )}
                {isAuthenticated && iconsToShow.includes("not-interested") && (
                  <div className="contest-card-icon-conatiner">
                    <img
                      src={thumbsDownImage}
                      alt="Not Interested"
                      title="Not Interested"
                    />
                    <p className="contest-card-icons-text">Not Interested</p>
                  </div>
                )}
                {isAuthenticated && iconsToShow.includes("bookmarked") && (
                  <div className="contest-card-icon-conatiner">
                    <img src={bookmarkImage} alt="Bookmark" title="Bookmark" />
                    <p className="contest-card-icons-text">Bookmark</p>
                  </div>
                )}
                {iconsToShow.includes("shares") && (
                  <div className="contest-card-icon-conatiner">
                    <Share url={urlToShare} />
                  </div>
                )}
                {iconsToShow.includes("post-your-query") && (
                  <div className="contest-card-icon-conatiner">
                    <img src={helpImage} alt="Questions" title="Questions" />
                  </div>
                )}
              </Menu>
            </div>
          )}
          <ContestDetail contestDetails={contestDetails} />
          {!hovered && (
            <div className="contest-card-company-container">
              <img src={companyImage} alt="Company" title="Company" />
              <p>{contestDetails.company}</p>
            </div>
          )}
          <div className="contest-card-cash-reward-container">
            <CashReward amount={contestDetails.cashReward} />
          </div>
          <div className="contest-card-company-experience-container">
            <div className="contest-card-company-container-hovered">
              <img src={companyImage} alt="Company" title="Company" />
              <p>{contestDetails.company}</p>
            </div>
            <div className="contest-card-experience-container">
              <img src={experienceImage} alt="Experience" title="Experience" />
              <p>{contestDetails.experience}</p>
            </div>
          </div>
          <div className="contest-card-join-hunt">
            <Link to={CONTEST_DETAILS.replace(":id", contestDetails.id)}>
              <Button
                label="Join Hunt"
                className="contest-card-join-hunt-btn"
              />
            </Link>
          </div>
          <div className="contest-card-icons-container">
            {contestDetails.matchingProfiles && (
              <div className="contest-card-icon-container">
                <div className="contest-card-icon-text-container">
                  <img src={matchingProfilesImage} alt="Matching Profiles" />
                  <p className="contest-card-icon-text">
                    {contestDetails.matchingProfiles}
                  </p>
                </div>
                <p className="contest-card-icons-container-text">
                  Matching Profiles
                </p>
              </div>
            )}
            <div className="contest-card-icon-container">
              <div className="contest-card-icon-text-container">
                <img src={quotaImage} alt="Total Quota" />
                <p className="contest-card-icon-text">
                  {contestDetails.totalQuota}
                </p>
              </div>
              <p className="contest-card-icons-container-text">Total Quota</p>
            </div>
            <div className="contest-card-icon-container">
              <div className="contest-card-icon-text-container">
                <img src={calendarImage} alt="Days Left" />
                <p className="contest-card-icon-text">
                  {contestDetails.daysLeft}
                </p>
              </div>
              <p className="contest-card-icons-container-text">Days Left</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContestCard;
