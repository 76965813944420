import axios from "axios";
import { CONTEST_API } from "../../../utils/constants/urls";

export default {
  getAll: async (userId) => {
    let response;
    if (userId) {
      response = await axios.get(`${CONTEST_API}?user_id=${userId}`);
    } else {
      response = await axios.get(CONTEST_API);
    }

    return response.data;
  },
};
