import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "./styles.scss";
import { acceleratedHiringList } from "../../utils/constants/site-data";

export const AcceleratedHiringSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(null);

  useEffect(() => {
    let interval;
    if (!isHovering) {
      interval = setInterval(() => {
        setActiveIndex(
          (prevIndex) => (prevIndex + 1) % acceleratedHiringList.length
        );
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isHovering]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      {acceleratedHiringList.map((item, index) => {
        const isActive = activeIndex === index || isHovering == index;
        return (
          <div
            key={item.id}
            className="accelerating-hiring-image-container"
            style={{
              width: isActive ? "40%" : "25%",
            }}
            onMouseEnter={() => {
              setIsHovering(index);
              setActiveIndex(null);
            }}
            onMouseLeave={() => {
              setIsHovering(null);
              setActiveIndex(index);
            }}
          >
            <div className="overlay" />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              position="absolute"
              bottom={0}
              px={2}
              color="white"
              textAlign="center"
              bgcolor={!isActive && "green"}
            >
              {isActive ? item.activeText : item.inactiveText}
            </Typography>
            <img
              src={item.src}
              alt={`Slide ${index}`}
              className="accelerating-hiring-image"
            />
          </div>
        );
      })}
    </Grid>
  );
};
