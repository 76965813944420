import axios from 'axios';
import { getLocalStorage } from "../../utils/storage-manager";
import { URL_PREFIX } from "../../utils/api/urls";

import Cookies from "universal-cookie";
// import { setLocalStorage } from '../../utils/storage-manager';



export const fetchOptions = (fieldKey) => {
    const apiUrl = `${process.env.REACT_APP_URL_PREFIX}/company/choices/${fieldKey}`;

    return axios.get(apiUrl)
        .then(response => {
            return response.data.options;
        })
        .catch(error => {
            console.error('Error fetching options:', error);
            return [];
        });
}



export const sendPostRequest = async (url, formData) => {

    const URL = `${process.env.REACT_APP_URL_PREFIX}/recruiter/${url}`;
    const Token = getLocalStorage("token");
    let config = {
        headers: {
            'Authorization': `Bearer ${Token}`,
            'Content-Type': 'application/json',
        }
    }

    try {
        const response = await axios.post(URL, formData, config);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const linksRequest = async (links) => {

    const url = `${process.env.REACT_APP_URL_PREFIX}/${links}`
    return axios.get(url)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error fetching options:', error);
            return [];
        });
}

export const linksRequest_autologin = async (links) => {
    const url = `${process.env.REACT_APP_URL_PREFIX}/${links}`;
    const userEmail = getLocalStorage("userEmail");
    try {

        const response = await axios.post(url, {
            email: userEmail
        });
        if (response.status == 200) {
            const { wp_auth } = response.headers;
            const cookieHeader = JSON.parse(wp_auth);
            const cookies = new Cookies();
            cookies.set(cookieHeader.cookie_name, cookieHeader.value, {
              domain: cookieHeader.cookie_domain,
              path: cookieHeader.cookie_path,
              expires: new Date(cookieHeader.expire * 1000),
              httpOnly: cookieHeader.http_only,
            });
            return response;
        }
    } catch (error) {
        console.error('Error fetching options:', error);
        return [];
    }
}




