

import "./styles.scss";
import image4 from "../../assets/image4.png";
import AimlImg from "../../assets/home-page/AIML driven.png";
import SwirilVector1 from "../../assets/home-page/SwirilVector1.png";
import RaceCar from "../../assets/home-page/RaceCarRecruitment.png";
import SwirilVector2 from "../../assets/home-page/SwirilVector2.png";
import AmazonofRecruitement from "../../assets/home-page/AmazonofRecruitement.png";
import AcceleratedHiring from"../../assets/home-page/AccleratedHiring.png";
import LowConversionRate from"../../assets/home-page/LowConversion.png";
import ChallengesImg1 from "../../assets/home-page/challengesimg1.png";
import Map from "../../assets/home-page/Map.png";
import HighTurn from"../../assets/home-page/HighTurn.png";
import LostRevenue from"../../assets/home-page/LostRevenue.png";
import oursolutioninitial from "../../assets/Front.png";
import oursolutionhover from "../../assets/oursolution.png";
import LeafLeft from "../../assets/leaf-left.png";
import LeafRight from "../../assets/leaf-right.png";
import quoteReverse from "../../assets/quote-reverse.png";
import quoteStraight from "../../assets/quote-straight.png";
import { Button, Card, Grid, IconButton, Typography, Box } from "@mui/material";
import { useState } from "react";
import Footer from "../../components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AcceleratedHiringSlider } from "../../components/AcceleratedHiringSlider";
import { openPopupWidget } from "react-calendly";
import {
  awards,
  clients,
  successstories,
  testimonials,
} from "../../utils/constants/site-data";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "../../utils/hooks";


export const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:1060px)");
  const fontSize = isMobile ? "h4" : "h3";
  const [activeTestmonial, setActiveTestmonial] = useState("vendor");
  const [activeSuccessStories, setActiveSuccessStories] = useState(
    "construction"
  );
  const [hover, setHover] = useState(false);

  const handleScheduleDemo = () => {
    return openPopupWidget({
      url: process.env.REACT_APP_SCHEDULE_DEMO_URL,
    });
  };



  const useStyles = makeStyles({
    tabRoot: {
      textTransform: 'none',
      minWidth: 120,
      margin: '0 1px',
      padding: '6px 12px',
      borderTopLeftRadius: "8px", borderTopRightRadius: "8px",
      WebkitBorderTopLeftRadius:"8px", WebkitBorderTopRightRadius:"8px",
      color: '#757575',
      fontSize:'large',
      '&.Mui-selected': {
        backgroundColor: '#4d6cd9',
        color: 'white',
      },
      
    },
    tabs: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  });

  const classes = useStyles();
  return (
    <Grid bgcolor="#fff">
      <Header />
      <Grid
        mt={10}
        container
        display="flex"
        paddingLeft={isMobile || isTablet ? 1 : 10}
        paddingRight={isMobile || isTablet ? 1 : 10}
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid
          item
          display="flex"
          flexDirection="column"
          textAlign={isMobile ? "center" : undefined}
          gap={3}
          md={4}
          sm={12}
          xs={12}
        >
          <Typography variant="h4" color="#4d6cd9">
            Harness The{" "}
            <Typography color="#626880" variant="h4" display="inline">
              Power
            </Typography>{" "}
            Of The Crowd To Fill Job Openings
          </Typography>
          <Typography>Accelerated, Precision hiring with Technology</Typography>
          <Grid
            item
            gap={5}
            display="flex"
            justifyContent={isMobile ? "center" : undefined}
          >
            <Button
              className="button button-primary"
              variant="contained"
              onClick={handleScheduleDemo}
            >
              Schedule a demo
            </Button>

           
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item md={6} justifyContent={"flex-end"} marginTop={5}>
            <AcceleratedHiringSlider />
          </Grid>
        )}
      </Grid>

<Grid
        xs={12}
        display={"flex"}
        justifyContent="center"
        marginTop="10%"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant={fontSize} marginBottom="5%">
          What <span className="primary-color">Hiringhood</span> does?
          
        </Typography>
        {isMobile ? (
          <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            width="95%"
          >
            <img src={AimlImg} style={{ width: "70%", height: "auto" }} />
            <img src={SwirilVector1} style={{ width: "25%", height: "20%" }} />
            <img src={RaceCar} style={{ width: "70%", height: "auto" }} />
            <img src={SwirilVector2} style={{ width: "25%", height: "20%" }} />
            <img src={AmazonofRecruitement} style={{ width: "70%", height: "auto" }} />
          </Grid>
        ) : (
          <img src={image4} width={isMobile ? "95%" : "70%"} />
        )}
      </Grid>
    
<Grid
        xs={12}
        display={"flex"}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        marginTop="3%"

         style={{
              // backgroundImage: `url(${Map})`,
              // backgroundSize: "contain",
              // backgroundRepeat: "repeat-y",
              // backgroundPosition: "center",
              padding: "10px",
            
            }}
      >
          <Typography variant={fontSize}  marginTop="10%" marginBottom="6%"
       >
          Challenges we <span className="primary-color">solve</span>
        </Typography>
        {isMobile ? (
          <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            width="95%"
          >
            <img src={ChallengesImg1} style={{ width: "80%", height: "auto" }} />
            <img src={AcceleratedHiring} style={{ width: "80%", height: "auto" }} />
            <img src={LowConversionRate} style={{ width: "80%", height: "auto" }} />
            <img src={HighTurn} style={{ width: "80%", height: "auto" }} />
            <img src={LostRevenue} style={{ width: "80%", height: "auto" }} />
            
          </Grid> 
        ) : (
          <img
            src={hover ? oursolutionhover : oursolutioninitial}
            width={isMobile ? "95%" : "70%"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        )}
       <Typography textAlign="center"  marginTop="4%">
          International IT Industry alone <span className="primary-color">losses $3 trillion/annum</span> over 79
          Million unfilled job positions
        </Typography>
      </Grid>
      

      <Grid
        container
        width="100%"
        marginY={isMobile ? "25%" : "5%"}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant={fontSize} marginY="5%">
          Our <span className="primary-color">Clients</span>
        </Typography>
        <Grid item width="100%" marginBottom="5%">
          <Slider
            dots={false}
            infinite={true}
            speed={"5000"}
            slidesToShow={isMobile ? 1 : isTablet ? 2 : 4}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={2000}
            cssEase={"linear"}
            rtl={true}
          >
            {clients.map((client, index) => (
              <img
                className="logos"
                src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${client.logo}`}
                key={index}
              />
            ))}
          </Slider>
        </Grid>
      </Grid>

      <Grid
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant={fontSize}>
          News <span className="primary-color">&</span> Awards{" "}
        </Typography>
        <Grid width="100%">
          <Slider
            dots={true}
            speed={500}
            autoplay={true}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplaySpeed={5000}
            prevArrow={<CustomPrevArrow />}
            nextArrow={<CustomNextArrow />}
          >
            {awards.map((award, index) => (
              <div key={index}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  paddingY={5}
                  flexDirection="column"
                  alignItems="center"
                  width={"100%"}
                >
                  <Grid
                    md={10}
                    container
                    borderRadius={5}
                    direction={"row"}
                    bgcolor={"#F6F7F8"}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      md={4}
                      sm={12}
                      display="grid"
                      flexDirection="column"
                      alignItems="center"
                      mx={5}
                    >
                      <Grid width="100%" display="flex" justifyContent="center">
                        <Typography
                          variant="h5"
                          borderBottom={1}
                          borderColor="#b5b1b1"
                          width="max-content"
                          my={1}
                        >
                          {award.title}
                        </Typography>
                      </Grid>
                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img height="150" src={LeafLeft} />
                        <Typography variant="h4" textAlign="center">
                          {award.heading}
                        </Typography>
                        <img height="150" src={LeafRight} />
                      </Grid>
                      <Typography variant="h6" textAlign="center" my={5}>
                        {award.description}
                      </Typography>
                      {(isMobile || isTablet) && (
                        <Grid
                          sm={12}
                          display={"flex"}
                          justifyContent={"center"}
                          overflow={"hidden"}
                        >
                          <img height={"180rem"} src={award.image} />
                        </Grid>
                      )}
                    </Grid>
                    {!isTablet && !isMobile && (
                      <Grid
                        py={5}
                        md={4}
                        sm={12}
                        display="flex"
                        overflow={"hidden"}
                        alignItems="center"
                      >
                        <img height={"230rem"} src={award.image} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>

    <Grid position="relative" marginY={15} marginRight={2} width={'100%'}>
        <Typography variant={fontSize} textAlign="center" marginBottom={5}>
          Testimonials
        </Typography>
        <img className="quote-reverse" src={quoteReverse} />
        
          <Tabs
            value={activeTestmonial}
            onChange={(e, newValue) => setActiveTestmonial(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
           
            
          >
            <Tab label="Vendor" value="vendor" className={classes.tabRoot} />
            <Tab label="Clients" value="clients"  className={classes.tabRoot}/>
            <Tab label="Job Seekers" value="jobseekers"  className={classes.tabRoot}/>
          </Tabs>
         
          <Grid padding={2} sx={{border:1, backgroundColor: "#E8EAFA", borderBlockColor:"#4d6cd9"}}>
            <Grid width={"100%"} display={"flex"} justifyContent={"center"}>
              <Grid width="90%" justifyContent={"center"}>
                <Slider
                  dots={true}
                  infinite={false}
                  speed={500}
                  slidesToShow={isMobile || isTablet ? 1 : 3}
                  slidesToScroll={1}
                  style={{ zIndex: 9 }}
                >
                  {testimonials[activeTestmonial].map((testimonial, index) => (
                    <div key={index}>
                      <Grid key={index} xs={10} margin={2}>
                        <Card className="testimonial-card" sx={{ height: "20rem" }}>
                          <Typography
                            variant="body2"
                            align="justify"
                            overflow={"auto"}
                            height={"15rem"}
                            className="hide-scroll-bar"
                          >
                            {testimonial.description}
                          </Typography>
                          <Grid
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"10px"}
                            borderTop={"1px solid #f0f0f6"}
                          >
                            <img
                              className="testimonial-logo"
                              src={testimonial.image}
                            />
                            <div>
                              <Typography variant="h6">
                                {testimonial.title}
                              </Typography>
                              <Typography variant="caption">
                                {testimonial.subtitle}
                              </Typography>
                            </div>
                          </Grid>
                        </Card>
                      </Grid>
                    </div>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Grid>
       
        <img className="quote-straight" src={quoteStraight} />
      </Grid> 

<Grid position="relative" marginY={15} marginRight={2} width={'100%'}>
      <Typography variant={fontSize} textAlign="center" marginBottom={5}>
        Our <span className="primary-color">Success</span> Stories
      </Typography>

      <Tabs
        value={activeSuccessStories}
        onChange={(e, newValue) => setActiveSuccessStories(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered 
        className={classes.tabs}
      >
        <Tab label="ConstructN.AI" value="construction" className={classes.tabRoot} />
        <Tab label="Aheadrace" value="aheadrace" className={classes.tabRoot} />
        <Tab label="Proarch" value="proarch" className={classes.tabRoot} />
      </Tabs>

      {/* <Card sx={{ border: 1, backgroundColor: "#E8EAFA", borderBlockColor: "#4d6cd9" }}> */}
        <Grid padding={5} sx={{ border: 1, backgroundColor: "#E8EAFA", borderBlockColor: "#4d6cd9" }}>
          <Typography variant="h4" fontWeight="bold" marginBottom={5} sx={{color:'black' }}>
            {successstories[activeSuccessStories].title}
          </Typography>
          <Typography
            variant="body1" 
            marginBottom={5}
            overflow={"auto"}
            maxHeight={"15rem"}
            whiteSpace={"pre-wrap"}
            className="hide-scroll-bar"
            textAlign={"justify"}
            sx={{color: '#626880'}}
            
          >
            {successstories[activeSuccessStories].description}
          </Typography>
        </Grid>
      {/* </Card> */}
    </Grid>
      <Footer />
    </Grid>
  );
};

const CustomPrevArrow = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { onClick } = props;
  return (
    <div className="reverseArrow">
      {!isMobile && (
        <IconButton size="large" color="primary" onClick={onClick}>
          <ArrowBackIosIcon />
        </IconButton>
      )}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const { onClick } = props;
  return (
    <div className="forwardArrow">
      {!isMobile && (
        <IconButton size="large" color="primary" onClick={onClick}>
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </div>
  );
};

