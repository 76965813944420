import { useState, useEffect } from "react";
import Button from "../Button";
import { fetchOptions, sendPostRequest } from "./Api";
import { getLocalStorage } from "../../utils/storage-manager";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@mui/icons-material/Close";

const ExperienceDetails = ({ onActiveFormChange }) => {
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    noOfRecruiters: [],
    recruitersOnHH: "",
    placements: "",
    branches: [],
    domainSkills: [],
    clients: [],
    typeOfJob: [],
    levelsYouRecruit: [],
    typeOfRequirements: [],
    handledRecruitment: [],
  });

  const selectedBranches = Object.entries(form.branches).reduce(
    (acc, [key, value], index) => {
      acc[index] = key;
      return acc;
    },
    {}
  );

  const selectedClients = Object.entries(form.clients).reduce(
    (acc, [key, value], index) => {
      acc[index] = key;
      return acc;
    },
    {}
  );

  const selectedTypeOfJob = Object.entries(form.typeOfJob).reduce(
    (acc, [key, value], index) => {
      acc[index] = key;
      return acc;
    },
    {}
  );

  const [branchesOp, setBranchesOp] = useState({});
  const [domainSkillsOp, setdomainSkills] = useState({});
  const [noOfRecOp, setNoOfRecOp] = useState({});
  const [clientOp, setClientOp] = useState({});
  const [typeOfJObOp, setTypeOfJObOp] = useState({});
  const [levelRecruitOp, setlevelRecruitOp] = useState({});
  const [typeOfReqOp, setTypeOfReqOp] = useState({});
  const [handledRecruitmentOp, sethandledRecruitmentOp] = useState({});

  useEffect(() => {
    const branchesKey = "field_62d9857ebb017";
    const domainSkillsKey = "field_6192c341aa60c";
    const noOfRecruitersKey = "field_6192c2c5afd73";
    const ClientsFieldKey = "field_62d9868fc5ef8";
    const typeOfJobFieldKey = "field_6192c5c1aa610";
    const levelRecruitFieldKey = "field_6192d22406918";
    const typeOfReqFieldKey = "field_6192c416aa60d";
    const handledRecruitmentFieldKey = "field_6192c64daa611";

    fetchOptions(branchesKey).then((options) => {
      setBranchesOp(options);
    });
    fetchOptions(domainSkillsKey).then((options) => {
      setdomainSkills(options);
    });
    fetchOptions(noOfRecruitersKey).then((options) => {
      setNoOfRecOp(options);
    });

    fetchOptions(ClientsFieldKey).then((options) => {
      setClientOp(options);
    });

    fetchOptions(typeOfJobFieldKey).then((options) => {
      setTypeOfJObOp(options);
    });

    fetchOptions(levelRecruitFieldKey).then((options) => {
      setlevelRecruitOp(options);
    });

    fetchOptions(typeOfReqFieldKey).then((options) => {
      setTypeOfReqOp(options);
    });

    fetchOptions(handledRecruitmentFieldKey).then((options) => {
      sethandledRecruitmentOp(options);
    });
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      no_recruiters_company_holds: form.noOfRecruiters,
      how_many_recruiters_working_on_hiringhood_platform: form.recruitersOnHH,
      placements_made_in_the_last_12_months: form.placements,
      locations_at_which_you_have_your_branches_currently: selectedBranches,
      five_functional_roles_recruiting_expertise: form.domainSkills,
      what_type_of_clients_have_currently: selectedClients,
      job_requisitions: selectedTypeOfJob,
      levels_of_hire_talent_mostly: form.levelsYouRecruit,
      type_of_requirements_your_handled: form.typeOfRequirements,
      handled_recruitment_drives: form.handledRecruitment,
    };

    onActiveFormChange("Verification");
    const id = getLocalStorage("id");
    sendPostRequest(`${id}/company/experience`, formData);
  };
  const backHandler = () => {
    onActiveFormChange("professional");
  };
  const skipHandler = () => {
    onActiveFormChange("TermsToUse");
  };

  return (
    <form onSubmit={handleSubmit}>
      <button className="skip" onClick={skipHandler}>
        Skip
      </button>
      <div>
        <label className="label">No. of Recruiters in the Organization</label>
        <select
          id="input-field"
          value={form.noOfRecruiters}
          onChange={handleChange}
          name="noOfRecruiters"
        >
          <option value="">Select an option</option>
          {Object.entries(noOfRecOp).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>

        <label className="label">
          How many Recruiters would be working on HiringHood platform
        </label>
        <input
          className={"input-field"}
          type="number"
          name="recruitersOnHH"
          placeholder="Type Here..."
          value={form.recruitersOnHH}
          onChange={handleChange}
        />

        <label className="label">
          How many placements where done over the past 12months
        </label>
        <input
          className={"input-field"}
          type="number"
          name="placements"
          placeholder="Type Here..."
          value={form.placements}
          onChange={handleChange}
        />

        <label className="label">
          Enter the locations at which you have your branches currently
        </label>
        <Multiselect
          options={Object.entries(branchesOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(form.branches).map(([key, value]) => ({
            key,
            value,
          }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, branches: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, branches: newSelected });
          }}
          placeholder="Select"
          // showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              // To change css for multiselect (Width,height,etc..)
              width: "97.3%",
              paddingTop: "4px",
              paddingBottom: "3px",
            },
            searchBox: {
              // To change search box element look
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              // To change input field position or margin
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              // To change css for option container
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />

        <label className="label">
          Enter the 5 domain Skills your company is good at recruiting
        </label>
        <Multiselect
          options={Object.entries(domainSkillsOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(
            form.domainSkills
          ).map(([key, value]) => ({ key, value }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, domainSkills: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, domainSkills: newSelected });
          }}
          placeholder="Select"
          // showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              width: "97.3%",
              paddingTop: "4px",
              paddingBottom: "4px",
            },
            searchBox: {
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />

        <label className="label">
          What type of Clients do you have currently
        </label>
        <Multiselect
          options={Object.entries(clientOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(form.clients).map(([key, value]) => ({
            key,
            value,
          }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, clients: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, clients: newSelected });
          }}
          placeholder="Select"
          // showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              width: "97.3%",
              paddingTop: "4px",
              paddingBottom: "4px",
            },
            searchBox: {
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />

        <label className="label">
          What type of job requestions your Clients recruit for?
        </label>
        <Multiselect
          options={Object.entries(typeOfJObOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(
            form.typeOfJob
          ).map(([key, value]) => ({ key, value }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, typeOfJob: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, typeOfJob: newSelected });
          }}
          placeholder="Select"
          // showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              width: "97.3%",
              paddingTop: "4px",
              paddingBottom: "4px",
            },
            searchBox: {
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />

        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <lable className="label">
              Handled Recruitment Drives in the past
            </lable>
            <select
              id="input-field"
              value={form.handledRecruitment}
              onChange={handleChange}
              name="handledRecruitment"
            >
              <option value="">Select an option</option>
              {Object.entries(handledRecruitmentOp).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="label">Type of Requirements you handled?</label>
            <Multiselect
              options={Object.entries(typeOfReqOp).map(([key, value]) => ({
                key,
                value,
              }))}
              selectedValues={Object.entries(
                form.typeOfRequirements
              ).map(([key, value]) => ({ key, value }))}
              displayValue="value"
              onSelect={(selectedList) => {
                const newSelected = Object.fromEntries(
                  selectedList.map(({ key, value }) => [key, value])
                );
                setForm({ ...form, typeOfRequirements: newSelected });
              }}
              onRemove={(selectedList) => {
                const newSelected = Object.fromEntries(
                  selectedList.map(({ key, value }) => [key, value])
                );
                setForm({ ...form, typeOfRequirements: newSelected });
              }}
              placeholder="Select"
              //  showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    fontSize: "0.9rem",
                    color: "red",
                    cursor: "pointer",
                    marginLeft: "0.3rem",
                  }}
                />
              }
              style={{
                multiselectContainer: {
                  width: "97.3%",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                },
                searchBox: {
                  borderRadius: "8px",
                  borderColor: "rgb(65, 65, 252)",
                  fontSize: "5px",
                  minHeight: "40px",
                  paddingBottom: "1px",
                },
                inputField: {
                  margin: "0.5px",
                  height: "0.5rem",
                },
                optionContainer: {
                  borderRadius: "8px",
                },
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.3rem 0.5rem",
                  color: "#000000",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
        </div>

        <label className="label">
          At what levels you recruit talent mostly
        </label>
        <Multiselect
          options={Object.entries(levelRecruitOp).map(([key, value]) => ({
            key,
            value,
          }))}
          selectedValues={Object.entries(
            form.levelsYouRecruit
          ).map(([key, value]) => ({ key, value }))}
          displayValue="value"
          onSelect={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, levelsYouRecruit: newSelected });
          }}
          onRemove={(selectedList) => {
            const newSelected = Object.fromEntries(
              selectedList.map(({ key, value }) => [key, value])
            );
            setForm({ ...form, levelsYouRecruit: newSelected });
          }}
          placeholder="Select"
          // showCheckbox
          showArrow
          customCloseIcon={
            <CloseIcon
              sx={{
                fontSize: "0.9rem",
                color: "red",
                cursor: "pointer",
                marginLeft: "0.5rem",
              }}
            />
          }
          style={{
            multiselectContainer: {
              paddingTop: "4px",
              paddingBottom: "4px",
            },
            searchBox: {
              borderRadius: "8px",
              borderColor: "rgb(65, 65, 252)",
              fontSize: "5px",
              minHeight: "40px",
              paddingBottom: "1px",
            },
            inputField: {
              margin: "0.5px",
              height: "0.5rem",
            },
            optionContainer: {
              borderRadius: "8px",
            },
            chips: {
              background: "rgba(155, 169, 232, 0.4)",
              padding: "0.3rem 0.5rem",
              color: "#000000",
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          }}
        />
      </div>
      <div className="nextBtn">
        <Button
          label="Next"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="submit"
        />
      </div>
      <div className="backBtn">
        <Button
          label="Back"
          variant="secondary"
          style={{ backgroundColor: "rgb(43, 43, 197)", color: "white" }}
          type="Back"
          onClick={backHandler}
        />
      </div>
    </form>
  );
};
export default ExperienceDetails;
