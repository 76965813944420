import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import logo from "../../assets/logo-white.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import telegram from "../../assets/telegram.svg";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";

import { footer as footerData } from "../../utils/constants/site-data";
import { COPYRIGHT } from "../../utils/constants/index";
import Contact from "../Contact";
import { setAuthenticationStatus } from "../../redux/actions/login";
import {
  getLocalStorage,
  resetLocalStorage,
} from "../../utils/storage-manager";
import { LOGIN } from "../../utils/constants/routes";
import { Grid } from "@mui/material";

const Footer = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
  };

  const isAuthenticated = useSelector(
    (state: RootStateOrAny) =>
      state.session.authenticated || getLocalStorage("token", null)
  );

  const handleMenuClick = (menu) => {
    if (menu.link) {
      history.push(menu.link);
    }

    if (menu.label === "Contact Us") {
      setOpen(true);
    }

    if (menu.label === "Sitemap") {
      window.open(`${process.env.REACT_APP_BASE_URL}/sitemap.xml`);
    }

    if (menu.label === "Login") {
      if (isAuthenticated) {
        dispatch(setAuthenticationStatus(false));
        resetLocalStorage("token");
        resetLocalStorage("user");
        resetLocalStorage("userId");
        resetLocalStorage("userRole");
        resetLocalStorage("userStage");
        resetLocalStorage("inviteSource");
        resetLocalStorage("profilePic");
      } else {
        history.push(LOGIN);
      }
    }

    if (menu.label === "Terms & Conditions") {
      window.open(
        `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Hiringhood.pdf`
      );
    }

    if (menu.label === "Privacy Policy") {
      window.open(
        `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Privacy+Policy_HiringHood.pdf`
      );
    }
  };

  return (
    <Grid container spacing={0}>
      <div className={styles["footer"]}>
      {/* <div className={styles["footer-wrapper"]}> */}
        
          <div className={styles["logo"]}>
            <img src={logo} alt="Hiringhood Logo"></img>
          
            <p>
              Hiringhood is a first-in-class recruiters aggregation marketplace
              technology platform, powered by AI/ML for accelerated hiring. <br />
              <br /> We are the first premium bounty hunting & crowd sourcing
              recruitment company.
            </p>
          </div>
          <div className={styles["footer-wrapper"]}>
          {footerData.map((item) => {
            <Grid item xs={8} sm={4} md={2} lg={1} key={item.title}></Grid>
            const content = Array.isArray(item.content) ? item.content : [];
            return (
              <div className={styles[item.title.toLowerCase()]} key={item.title}>
                <div className={styles["footer-item-title"]}>{item.title}</div>
                <ul>
                  <div className={styles["menu-item-container"]}>
                    {content.map((menu, i) => (
                      <li
                        key={i}
                        className={
                          menu.isDisabled ? styles["footer-item-disabled"] : ""
                        }
                        onClick={() => handleMenuClick(menu)}
                      >
                        {menu.label === "Login" && isAuthenticated
                          ? "Logout"
                          : menu.label}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            );
          })}

          <div className={styles["support"]}>
            <div className={styles["support-title"]}>Support</div>
            <ul>
              <div>
                <li>
                  Hiringhood, T-Hub 2.0, #20, Inorbit Mall Rd, Vittal Rao Nagar,
                  Madhapur, Hyderabad, Hyderabad 500081
                </li>
              </div>
            </ul>
            <div className={styles["social-icons"]}>
              <div className={styles["social-icon"]}>
                <a href="https://www.facebook.com/hiringhood/?locale=fr_CA" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" />
                </a>
              </div>
              <div className={styles["social-icon"]}>
                <a href="https://x.com/hiringhood" target="_blank" rel="noopener noreferrer">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
              <div className={styles["social-icon"]}>
                <a href="https://www.instagram.com/hiringhood/" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="instagram" />
                </a>
              </div>
              <div className={styles["social-icon"]}>
                <a href="https://www.linkedin.com/company/hiringhood/mycompany/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="Linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Grid xs={15}
          md={28}
          lg={0}
        >
          <hr className={styles["separator"]} /></Grid>
        <div className={styles["copyright-container"]}>
          <p className={styles["copyright"]}>{COPYRIGHT}</p>
        </div>
        {open && (
          <Contact
            onClose={onClose}
            showComapnyAndDesignation={false}
            showMessage
          />
        )}
      </div>
    </Grid>
  );
};

export default Footer;
