import "./styles.css";
import logo from "../../assets/logo-white.svg";

const RefundPolicy = () => {
    const currentDate = new Date();
    const dateString = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
  return (
    <div className="parent-container">
      <div className="container1">
        <div className="textContainer">
          <h1>Refund Policy</h1>
          <p>Read our refund policies to learn more </p>
          <p> about your rights and responsibilities</p>
          <div className='refund-policy-Logo'>
              <img src={logo} alt="Logo" className="logo" />
        </div>
        </div>
        
      </div>
      <div className="refund-policy-container">
        <p>
          Effective Date: {dateString}
          <br />
          <br/>
          <strong>For Employers</strong>
        </p>
        <h3>1. Introduction</h3>
        <p>
          HiringHood (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;)
          operates the HiringHood platform (the &quot;Platform&quot;), providing
          innovative recruitment solutions. By using the Platform and engaging
          with our services, you agree to adhere to this Cancellation and Refund
          Policy (&quot;Policy&quot;).
        </p>
        <h3>2. Recurring Subscription Refund</h3>
        <p>
          <strong>2.1. Refund Eligibility:</strong><br/> 
          Subscriptions are
          non-refundable, except where explicitly stated in this Policy.
          <br />
          <br/>
          <strong>2.2. Refund Process:</strong><br/>
           In the event that HiringHood
          decides to refund a subscription fee for exceptional reasons, the
          refund amount will be processed within 15 (fifteen) working days from
          the date of the refund decision. Refunds will be processed using the
          original payment method chosen by you.
          <br />
          <br/>
          <strong>2.3. Non-Cash Refunds:</strong><br/>
           We do not provide cash
          refunds. Refunds will be issued using the original payment method, and
          processing times may vary depending on your bank or financial
          institution.
        </p>
        <h3>3. Modification and Amendment</h3>
        <p>
          HiringHood reserves the right to modify, amend, alter, or vary the
          terms and conditions of this Policy at its sole discretion. Any
          changes will be communicated through our website and updated Policy.
        </p>
        <h3>4. Contact Information</h3>
        <p>
          If you have any questions, concerns, or clarifications regarding this
          Policy, please contact HiringHood&apos;s customer support team at
          support@hiringhood.com. To facilitate communication, please provide
          relevant information, including your contact number or registered
          mobile number.
        </p>
        <h3>5. Additional Cancellation Policy for Recruiters</h3>
        <p>
          Recruiters using the HiringHood Platform are subject to an additional
          cancellation policy, which outlines terms for accepting, rejecting,
          and cancelling assignments. Please refer to the separate
          &quot;Cancellation Policy for Recruiters&quot; document for detailed
          information.
        </p>
        <h3>6. Governing Terms</h3>
        <p>
          This Cancellation and Refund Policy is subject to the HiringHood Terms
          of Use. In case of any conflict or inconsistency, the HiringHood Terms
          of Use shall prevail.
        </p>
        <p style={{paddingBottom:"3rem"}}>
          Please note that this is a sample refund policy and should be reviewed
          by legal professionals to ensure it aligns with your company&apos;s
          specific terms and local regulations.
        </p>
      </div>
    </div>
  );
};
export default RefundPolicy;
