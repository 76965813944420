import Integration from "../../assets/integration.svg";
import Recrutier from "../../assets/recruiter.svg";
import Partners from "../../assets/partners.svg";
import Candidates from "../../assets/candidates.svg";
import Revenue from "../../assets/revenue.svg";
import ProfileOwnership from "../../assets/profile-ownership.svg";
import PartnersLarge from "../../assets/partners-200px.svg";
import CandidatesLarge from "../../assets/candidates-200px.svg";
import HiringLarge from "../../assets/hiring-200px.svg";
import Contests from "../../assets/contests.svg";
import Resumes from "../../assets/resumes.svg";
import Recrutiers from "../../assets/recruiters.svg";
import Rewards from "../../assets/rewards-80px.svg";
import GreenCheckImage from "../../assets/mark-green.svg";
import RedErrorImage from "../../assets/mark-red.svg";
import OneShop from "../../assets/one-shop.svg";
import QuickCash from "../../assets/quick-cash.svg";
import Hiring from "../../assets/hiring.svg";
import VattedRecruiters from "../../assets/vatted-recruiters.svg";
import Bandwidth from "../../assets/bandwidth.svg";
import Position from "../../assets/position.svg";
import Employer from "../../assets/employer.svg";
import CandidatesWhite from "../../assets/candidates-white.svg";
import PartnersWhite from "../../assets/partners-white.svg";
import ProfileOwnershipWhite from "../../assets/profile-ownership-white.svg";
import RupeeBag from "../../assets/rupee-bag.svg";
import ResumeLarge from "../../assets/resume-large.svg";
import Growth from "../../assets/growth.svg";
import Recognition from "../../assets/recognition.svg";
import Prize from "../../assets/prize-badge.svg";
import contest from "../../assets/home-page/contest.svg";
import contestSelected from "../../assets/home-page/contest-selected.svg";
import recruiter from "../../assets/home-page/recruiter.svg";
import recruiterSelected from "../../assets/home-page/recruiter-selected.svg";
import employer from "../../assets/home-page/employer.svg";
import employerSelected from "../../assets/home-page/employer-selected.svg";
import jobSeeker from "../../assets/home-page/job-seeker.svg";
import jobSeekerSelected from "../../assets/home-page/job-seeker-selected.svg";
import partner from "../../assets/home-page/partner.svg";
import partnerSelected from "../../assets/home-page/partner-selected.svg";
import referrals from "../../assets/home-page/referrals.svg";
import referralsSelected from "../../assets/home-page/referrals-selected.svg";
import { ABOUT_US, CONTESTS, FAQ, SIGNUP, REFUND_POLICY } from "./routes";
import acceleratedHiring from "../../assets/ah1.png";
import acceleratedHiringT from "../../assets/ah2.png";
import acceleratedHiringP from "../../assets/ah3.png";
import apolis from "../../assets/apolis.png";
import aheadrace from "../../assets/aheadrace.png";
import ConstructN from "../../assets/ConstructN.png";
import NirmalVarma from "../../assets/NirmalVarma.jpeg";
import Pradeep from "../../assets/Pradeep.jpeg";
import Vivek from "../../assets/Vivek.png";
import techsofy from "../../assets/techsofy.png";
import career from "../../assets/career.png";
import clark from "../../assets/clark.png";
import seedRound from "../../assets/seedroundImage.jpg";
import hysea from "../../assets/Hysea.png";
import risingStar from "../../assets/RisingStar.jpg";
import tSpark from "../../assets/T-Spark.png";
import indiaStartup from "../../assets/IndiaStartup.png";
import tAngel from "../../assets/TAngel.png";
import idream from "../../assets/IdreamEntrepreneur.png";
import idream2 from "../../assets/IdreamEntrepreneur2.png";

export const whatsInItForRecrutiersInfoArray = [
  {
    icon: Revenue,
    title: "Maximum Revenue",
    description:
      "Earn upto 25% higher fee for each candidate you successfully onboard",
  },
  {
    icon: Candidates,
    title: "Multiple Empanelment",
    description:
      "Just sign up with Hiringhood and you are automatically empaneled to multiple Employers",
  },
  {
    icon: Partners,
    title: "Higher Hit rate",
    description:
      "Increase the probability of placing the job seekers through exclusive eco-system.",
  },
  {
    icon: ProfileOwnership,
    title: "Secure Ownership",
    description:
      "Retain ownership up to 12 months across all employers through our platform",
  },
  {
    icon: Recrutier,
    title: "Fast Paced Interview Process",
    description:
      "Accelerated recruitment through weekend drives leveraging dedicated interview panels.",
  },
  {
    icon: Integration,
    title: "Best in Class Curation",
    description: "Leverage our 3rd party assessment platforms for curation.",
  },
];

export const siteMetricsArray = [
  {
    icon: Contests,
    metric: "250+",
    description: "Contests",
    bgColor: "#4898F0",
  },
  {
    icon: Resumes,
    metric: "30K",
    description: "Resumes",
    bgColor: "#00C15D",
  },
  {
    icon: Recrutiers,
    metric: "100+",
    description: "Recrutiers",
    bgColor: "#F5BC2F",
  },
  {
    icon: Rewards,
    metric: "1.25 Cr",
    description: "Rewards",
    bgColor: "#EF706F",
  },
];

export const hiringhoodVsOthersHeaders = ["", "Hiringhood", "Others"];

export const hiringhoodVsOtherData = [
  {
    feature: "Earning Potential",
    hh: { text: "Upto 10.33%", icon: GreenCheckImage },
    others: { text: "Upto 8.33%", icon: RedErrorImage },
  },

  {
    feature: "Sourcing Model",
    hh: { text: "Bounty Hunt", icon: GreenCheckImage },
    others: { text: "Generic", icon: RedErrorImage },
  },

  {
    feature: "Recruiter Dashboard",
    hh: { text: "Free", icon: GreenCheckImage },
    others: { text: "Paid", icon: RedErrorImage },
  },
  {
    feature: "Recruiter Profiles",
    hh: { text: "Free", icon: GreenCheckImage },
    others: { text: "Not Available", icon: RedErrorImage },
  },

  {
    feature: "Profile Ownership",
    hh: { text: "Upto 12 months across the platform", icon: GreenCheckImage },
    others: { text: "6 months/client", icon: RedErrorImage },
  },

  {
    feature: "Payout",
    hh: { text: "Best in Market. As early as 45 days", icon: GreenCheckImage },
    others: { text: "3 Months", icon: RedErrorImage },
  },

  {
    feature: "Invite only Recruitment",
    hh: { text: "Yes", icon: GreenCheckImage },
    others: { text: "No", icon: RedErrorImage },
  },

  {
    feature: "Closure SLA - Turn Around Time",
    hh: { text: "4 weeks or less ", icon: GreenCheckImage },
    others: { text: "2 months or more", icon: RedErrorImage },
  },
];

export const drivingImpactInfo = [
  {
    icon: CandidatesLarge,
    title: "Candidate Experience",
    stat: "+166%",
    description: "More Applications",
  },
  {
    icon: HiringLarge,
    title: "Employer Time to Hire",
    stat: "+63%",
    description: "Faster Response Time",
  },
  {
    icon: PartnersLarge,
    title: "Recruiter Productivity",
    stat: "+166%",
    description: "More Time to Spend on Relationship Building",
  },
];

export const whyHiringhoodRecruitersArray = [
  {
    icon: Revenue,
    title: "Maximum Revenue",
    description: "Up to 10.33% on CTC, 25% higher than the industry standard",
  },
  {
    icon: OneShop,
    title: "One Stop Shop",
    description:
      "Just sign up with Hiringhood and you are automatically empaneled to multiple Employers",
  },
  {
    icon: QuickCash,
    title: "Quick to Cash",
    description: "Best in Market. As early as 45 days",
  },

  {
    icon: ProfileOwnership,
    title: "Profile Ownership",
    description:
      "Twelve month ownership of profiles sourced irrespective of the clients across our platform",
  },
];

export const whyHiringhoodEmployersArray = [
  {
    icon: Hiring,
    title: "Accelerated Hiring",
    description:
      "Hoards of bounty recruiters compete for closure at war footing.",
  },
  {
    icon: Integration,
    title: "Integration with 3rd parties",
    description:
      "Leverage integrations with ATS, Curation, Assessment, Evaluation, BGV & other services ",
  },
  {
    icon: Bandwidth,
    title: "Engineering Bandwidth",
    description: "Save cost, time & effort of your engineering teams ",
  },
  {
    icon: VattedRecruiters,
    title: "Vetted Recruiters",
    description:
      "Engage qualified recruiters with relevant domain expertise related to your JD",
  },
];

export const whyHiringHoodRecruitersText = {
  title: "Recruiters",
  description:
    "Hiringhood brings a unique bounty hunting recruitment model to the table which the recruiters can leverage to maximize revenue on one hand and multiple their empanelment on the other. Recruiters now get exclusive ownership of resumes they upload eliminating the fear of losing job seekers due to duplication.",
};

export const whyHiringhoodEmployersText = {
  title: "Employers",
  description:
    "Gone are the days when companies had to wait for 6 months to find an employee. Hiringhood has found a unique method to accelerate hiring for employers by aggregating the best recruiters in the job market for a specific domain/ technology, vetting them to chase talent immediately available at a premium fulfillment fee.",
};

export const clients = [
  {
    name: "techsophy",
    logo: "techsophy_logo.png",
    website: "https://www.techsophy.com/",
  },
  {
    name: "Construction Specialties",
    logo: "constructionSpecialities_logo.png",
    website: "https://www.c-sgroup.com/",
  },
  {
    name: "ATMECS Global",
    logo: "atmecs_global.png",
    website: "https://www.atmecs.com/",
  },
  {
    name: "Dalhyd",
    logo: "Dalhyd_Logo.jpg",
    website: "https://www.dalhyd.com/",
  },
  {
    name: "Okoders",
    logo: "Okoders.png",
    website: "http://okoders.com/",
  },
  {
    name: "nslhub",
    logo: "nslhub.svg",
    website: "https://nslhub.com/",
  },
  {
    name: "Pactera EDGE",
    logo: "pacteraedge.png",
    website: "https://www.pacteraedge.com/",
    background: "rgb(28, 68, 127)",
  },
  {
    name: "Locuz",
    logo: "locuz.png",
    website: "https://www.locuz.com/",
  },
  {
    name: "KoFax",
    logo: "kofax.svg",
    website: "https://www.kofax.com/",
  },
  {
    name: "Blueetra",
    logo: "blumetra.svg",
    website: "https://blumetra.com/",
  },
  {
    name: "Knot Solutions",
    logo: "knotsolution.png",
    website: "https://knotsolutions.com/",
  },
  {
    name: "ACL Digital",
    logo: "acl-digital.jpeg",
    website: "https://www.acldigital.com/",
  },
  {
    name: "Proarch",
    logo: "proarch.webp",
    website: "https://www.proarch.com/",
  },
  {
    name: "Harsco",
    logo: "harsco.png",
    website: "https://www.harsco.com/",
  },
  {
    name: "RaceAhead",
    logo: "aheadrace.png",
    website: "https://www.aheadrace.com/",
    background: "#f36c21",
  },
  {
    name: "Indian Staffing Federation",
    logo: "isf.png",
    website: "https://www.indianstaffingfederation.org/",
  },
  {
    name: "Sureify",
    logo: "sureify.png",
    website: "https://www.sureify.com/",
  },
  {
    name: "Vis",
    logo: "visnet.png",
    website: "https://www.visnet.in/",
  },
  {
    name: "Freeflow",
    logo: "freeflow.png",
    website: "https://freeflow.zone/",
  },
  {
    name: "Bud-e",
    logo: "bude.png",
    website: "https://bud-e.in/",
  },
  {
    name: "Construct N",
    logo: "constructn.png",
    website: "https://www.constructn.ai/",
  },
  {
    name: "DataLink",
    logo: "datalink.png",
    website: "https://datalinksoftware.com/",
  },
];

export const valuesAtOurCoreText =
  " Offer best-in-class recruiter aggregation & market-place platform for accelerated hiring, using technologies, that help all key stakeholders in the echo system.";

export const ourCommitmentArray = [
  {
    icon: Position,
    metric: "02",
    description: "Years of work together",
    bgColor: "#EF706F",
  },
  {
    icon: PartnersWhite,
    metric: "09+",
    description: "Projects Completed",
    bgColor: "#EF9949",
  },
  {
    icon: CandidatesWhite,
    metric: "500+",
    description: "Recruiters on the platfrom",
    bgColor: "#ECB223",
  },
  {
    icon: ProfileOwnershipWhite,
    metric: "84%",
    description: "Of all Hiring Contests Closed Successfully",
    bgColor: "#1CCC71",
  },
  {
    icon: Employer,
    metric: "100+",
    description: "Number of job seekers placed",
    bgColor: "#14B1DE",
  },
  {
    icon: RupeeBag,
    metric: "₹5Cr+",
    description: "Bounty amount distributed",
    bgColor: "#6F8BEF",
  },
];

export const ourCommitmentTopics = [
  {
    icon: ResumeLarge,
    title: "Resume",
    description:
      "We believe that great resumes speak on behalf of the recruiters. We take pride in helping all recruiters to source great resumes and show case their incredible expertise.",
  },
  {
    icon: Growth,
    title: "Growth",
    description:
      "The Recruiter Journey does not end after sourcing the resume. The platform learns from previous contests and matches the best recruiters to a contest and informs them.",
  },
  {
    icon: Recognition,
    title: "Recognition",
    description:
      "We celebrate recruiters. We know the importance of being recognized for your work and skills in sourcing candidates.",
  },
];

export const whatCustomersAreSayingArray = [
  {
    quote:
      "Thanks for helping us source a few of our critical roles over the last six months. It enabled us to mitigate some of the program risks we were dealing. I am looking forward to continued assistance and want to grow this relationship further in other work areas, thus enabling us to reach new heights..",
    title: "Dilip Kumar Solasa",
    supportedBy: "TechSophy",
    date: "April 2022",
    profileImage: "Dilip-ProfilePic-TechSophy.jpg",
  },
  {
    quote:
      'I was just talking with someone tonight that said "Recruiters are a waste of time" and I said "Not HRS!" and I went on to explain what a positive experience I had with you when you placed me in my role.',
    title: "Project Manager - Biopharma Company",
    supportedBy: "HRS",
    date: "Dec 2018",
    profileImage: "avatar-male.png",
  },
  {
    quote:
      "I have been working with Eve, in HRS, for the past month. This was my first experience working with a recruiter and it has been amazing. I couldn´t have asked for more.",
    title: "PhD graduate",
    supportedBy: "HRS",
    date: "May 2021",
    profileImage: "avatar-male.png",
  },
];

export const whatMakesUsDifferentArray = [
  {
    icon: Prize,
    metric: "Bounty Hunting",
    description:
      "We plan to tackle the hard to chew problem of hiring the best candidates with a unique bounty hunting model paired with subject matter expertise of recruiters, streamlined into sub domains on the platform. Our funnel generates the best talent against  a Job Description available in the job marketplace.",
    bgColor: "#FFFFFF",
  },
];

export const homePageFAQ = [
  {
    image: contest,
    selectedImage: contestSelected,
    title: "Contest",
    faq: [
      {
        question: "What is a Contest?",
        answer:
          "Contest is nothing but a job requisition from the Employer which needs to be filled in an accelerated manner. It consists of vital information, such as Job description with Skill, Experience, Timeline, location(s), salary range, placement fee offered,educational qualification etc. A contest can have multiple positions.",
      },
      {
        question: "How can I participate in a Contest?",
        answer:
          "Contest is nothing but a job requisition from the Employer which needs to be filled in an accelerated manner. It consists of vital information, such as Job description with Skill, Experience, Timeline, location(s), salary range, placement fee offered,educational qualification etc. A contest can have multiple positions.",
      },
    ],
  },
  {
    image: recruiter,
    selectedImage: recruiterSelected,
    title: "Recruiter",
    faq: [
      {
        question: "How does Hiringhood work for recruiters?",
        answer:
          "If you are an independent recruiter or an employee in a recruitment Company, then working with Hiringhood is easy and rewarding. Here is how it works: Hiringhood has multiple job requisitions from hundreds of employers. Recruiters can fill these positions and earn a placement fee for each successful onboarding. They need to register, review jobs and then start submitting job seeker profiles. If the job seeker you have submitted into the platform is hired post duplication check, you will receive the placement fee.",
      },
      {
        question: "How does a recruiter benefit from Hiringhood?",
        answer:
          "Hiringhood provides access to thousands of jobs from top employers across India all its empanelled Vendors., who may or may not work with independent recruiters and agencies. Instead of wasting time, cold calling & trying to negotiate contracts with employers  you can spend your time recruiting more jobseekers by working with Hiringhood.",
      },
      {
        question:
          "When do I get paid for successful placements? How will I receive my payment?",
        answer:
          "1. You will be paid in 45 days following the date of your jobseeker starts working for the employer. In rare cases, it might take 90 days, but recruiters will be notified. <br/> <br/> 2. You can choose to receive a check via mail, or we can directly deposit your placement fee electronically in an account of your choice following the guarantee period.",
      },
      {
        question:
          "What if the client (employer) hires my jobseeker for another role? Will I be paid?",
        answer:
          "Hiringhood’s agreement with clients (employers) ensures that you will get paid if the client (employer) hires your jobseeker for another role within 90 days from the date of submission.",
      },
      {
        question:
          "What is the ownership period of my submitted jobseeker’s profiles?",
        answer:
          "Hiringhood will provide 90 calendar days of ownership to the recruiters from the date of successful profile submission, good news is that Hiringhood just don’t provide the ownership only for the employer submitted for but also across Hiringhood platform. So you have got plenty of chances for placement conversion.",
      },
      {
        question: "What is the highest placement fee?",
        answer:
          "The highest placement fee for jobs on Hiringhood is 16.66% on the job seeker's offered CTC. The fee is displayed to recruiters next to each job requisition (Contests).",
      },
      {
        question:
          "How long does it usually take for employers to respond to a jobseeker’s profile submission?",
        answer:
          "The average response time for the employer once a jobseeker’s profile has been submitted to the system is 2-3 business days. However, this completely varies Employer to Employer.",
      },
      {
        question: "How can I join as a Recruiter at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Recruiter category, so that one of our executives will get in touch with you. Also, you can send your queries to recruiter@hiringhood.com.",
      },
    ],
  },
  {
    image: employer,
    selectedImage: employerSelected,
    title: "Employer",
    faq: [
      {
        question: "How does Hiringhood work for employers?",
        answer:
          "Your jobs go live on the platform: An account manager, who is assigned to you, will work with you to upload a job description as well as notes (called Job Insights) for each of your jobs to help the recruiting community understand who you are looking to hire. <br/> <br/>The search for your perfect job seekers begins: The moment your jobs go live on the Platform, the right set of independent recruiters and recruiting firms on the Hiringhood platform will begin submitting jobseeker’s profiles to Hiringhood.<br/> <br/> You see only the best jobseeker profiles: One dedicated account manager, along with Hiringhood’s technology, will filter all incoming submissions. Information about jobseekers who are approved by your account manager will be sent to you. You can review incoming jobseekers straight from the Hiringhood platform, in your inbox, or even through your Applicant Tracking System (ATS).",
      },
      {
        question: "Why use Hiringhood?",
        answer:
          "By using Hiringhood, you instantly extend your reach to find better job seekers in less time. Traditional talent acquisition methods cannot give you the reach of a large community of recruiters. Having said that, SPEED is our motto. You will receive minimum 5 job seeker’s profile within  24 to 48 hours from the job requirement is given and our speciality is we only sumit profiles with immediate or less than 30 days of notice period, so the chances of conversion is very high and our requirement closing SLA is 2 weeks.",
      },
      {
        question: "How can I reach Hiringhood for questions?",
        answer:
          "You can call your assigned Account Manager at Hiringhood or send an email. Our team is available between 9 AM to 6 PM IST. However we are flexible enough to resolve all your queries at the earliest.",
      },
      {
        question: "How can I join as an Employer at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Employer category, so that one of our executives will get in touch with you. Also, you can send your queries to employer@hiringhood.com.",
      },
    ],
  },
  {
    image: jobSeeker,
    selectedImage: jobSeekerSelected,
    title: "Job Seeker",
    faq: [
      {
        question: "Who is a Jobseeker?",
        answer:
          "Job seeker refers to an individual who is actively looking for an employment opportunity/job.",
      },
      {
        question:
          "Can a Job seeker directly submit his/her profile for any open contest/position at Hiringhood platform?",
        answer:
          "No, Hiringhood does not accept any profiles coming directly from. One must be registered with Hiringhood as a Job seeker in order to apply for any open positions at Hiringhood platform.",
      },
      {
        question: "How can I join as a Jobseeker at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Jobseeker category, so that one of our executives will get in touch with you. Also, you can send your queries to jobseeker@hiringhood.com.",
      },
    ],
  },
  {
    image: partner,
    selectedImage: partnerSelected,
    title: "Partner",
    faq: [
      {
        question: "Who is called a partner at Hiringhood?",
        answer:
          "We at Hiringhood partnered with different service providers such as, Interview as service, LMS, Resume builder, Assessment Services. They will help Hiringhood in various stages as per their nature of service.",
      },
      {
        question: "How can I join as a Partner at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the partner category, so that one of our executives will get in touch with you. Also, you can send your queries to partner@hiringhood.com.",
      },
    ],
  },
  {
    image: referrals,
    selectedImage: referralsSelected,
    title: "Referrals",
    faq: [
      {
        question: "What is Referral program?",
        answer:
          "Anyone can enrol in Hiringhood referral program and earn referral fee of up to 500,000 INR on successful conversion by referring any of your friends or colleagues who are looking for job opportunities for any open contest/position in Hiringhood platform and making them join the employer once offered a position.",
      },
      {
        question: "Can I submit a referral directly?",
        answer:
          "No, Hiringhood will not consider any referrals directly from. One must be registered under the Hiringhood Referral program in order to refer their friends or colleagues.",
      },
      {
        question: "Who can join as a referral?",
        answer:
          "Anyone can participate in a referral program other than the users who are registered as Recruiters in Hiringhood.",
      },
      {
        question:
          "What is the difference between Employee referral and Hiringhood Referral?",
        answer:
          "Employee referral is nothing but you are referring your friends or close acquaintances to your company for any open positions and you will be paid a referral fee once they join your company and complete a cool off period.Hiringhood Referral is nothing but you are referring your friends or colleagues to any open positions at Hiringhood platform and get paid a referral fee once they join any one of Hiringhood client’s within 4 months from the referral date. This referral fee paid upon the successful completion of the cool off period.",
      },
      {
        question: "What are the benefits of joining a referral program?",
        answer:
          "Hiringhood Referral is a premium referral program which will pay the premium referral fee upon the conversion of your referral. You can earn up to 500,000 INR for a single referral upon successful conversion.",
      },
      {
        question: "How can I join the Hiringhood referral program?",
        answer:
          "Please register yourself in our website www.hiringhood.com under referral program, so that one of our executives will get in touch with you. Also, you can send your queries to referral@hiringhood.com.",
      },
    ],
  },
];

export const partners = [
  {
    category: "Assessment Platforms",
    partners: [
      {
        name: "Hackerrank",
        logo: "hackerrank.png",
        website: "https://www.hackerrank.com/",
      },
      {
        name: "Hackerearth",
        logo: "hackerearth.svg",
        website: "https://www.hackerearth.com/",
      },
    ],
  },
  {
    category: "Others",
    partners: [
      {
        name: "RChilli",
        logo: "rchilli.png",
        website: "https://www.rchilli.com/",
      },
    ],
  },
];

export const footer = [
  {
    title: "Information",
    content: [
      {
        label: "About Us",
        link: ABOUT_US,
      },
      {
        label: "Terms & Conditions",
      },
      {
        label: "Privacy Policy",
      },
      {
        label: "Refund Policy",
        link: REFUND_POLICY,
      },
      {
        label: "Careers With Us",
        link: CONTESTS,
      },
      {
        label: "Sitemap",
      },
      {
        label: "Contact Us",
      },
      {
        label: "FAQs",
        link: FAQ,
      },
    ],
  },
  {
    title: "Recruiters",
    content: [
      // {
      //   label: "Register Now",
      //   link: SIGNUP,
      // },
      {
        label: "Search Jobs",
        link: CONTESTS,
      },
      {
        label: "Login",
      },
      {
        label: "Create Job Alert",
        isDisabled: true,
      },
      {
        label: "Report A Problem",
        isDisabled: true,
      },
      {
        label: "Hiringhood Blogs",
      },
      {
        label: "Mobile Site",
        isDisabled: true,
      },
    ],
  },
];

export const team = [
  {
    image: "sarath.png",
    name: "Sarath Yandrapalli",
    role: "Founder and CEO",
    description:
      "Entrepreneur at heart, determined & passionate to solve deep problems with disruption, yet well-balanced business models which has intrinsic value for all key stake holders. Takes pride in working for his Customers, Vendors, Employees & Investors.",
    linkedInUrl: "https://www.linkedin.com/in/sarath-babu-yandrapalli-2048244",
  },
  {
    image: "aditya.jpg",
    name: "Aditya Boni",
    role: "Co-Founder and Head of Product",
    description:
      "Passionate Technical Product Leader with 8+ years of experience working for multiple startup across different domains including HR tech. Alumni of BITS (Goa) B.E. Hons in Computer Science & MBA from GITAM University.",
    linkedInUrl: "https://in.linkedin.com/in/boniaditya",
  },
  {
    image: "arvind.png",
    name: "Arvind Kandula",
    role: "Technical Advisor ",
    description:
      "Recognized as a strategist and visionary technologist,  holds the esteemed position of an official member of the Forbes Technology Council.",
    linkedInUrl: "https://www.linkedin.com/in/arvindkandula/",
  },
];

export const acceleratedHiringList = [
  {
    id: 1,
    src: acceleratedHiring,
    activeText: "Accelerated Hiring",
    inactiveText: "A",
  },
  {
    id: 2,
    src: acceleratedHiringP,
    activeText: "Precision Hiring",
    inactiveText: "P",
  },
  {
    id: 3,
    src: acceleratedHiringT,
    activeText: "Tech-Based Hiring",
    inactiveText: "T",
  },
];

// export const homepageClients = [okoders, nslhub, constructn, proarch];

export const testimonials = {
  vendor: [
    {
      image: apolis,
      title: "Apolis",
      description:
        "It has been a very encouraging journey until now. We have closed a few positions and received good bounties as well.We are looking forward to working on different skills and achieving more closures.Thanks for all the coordination and for being informative all the time; it has helped us a lot.",
      subtitle: "CEO of Apolis",
    },
    {
      image: career,
      title: "Career Hunt",
      description:
        "I wanted to express my deepest gratitude for your support throughout the process. Your dedication to fostering a positive work environment and inclusivity, along with the training sessions and professional development opportunities, are truly appreciated. Special thanks to the HR team for their exceptional communication and responsiveness. Your professionalism, support, and genuine care for vendors' well-being make a significant difference. Thank you for your passion and genuine interest in helping.",
      subtitle: "CEO of Career hunt",
    },
    {
      image: clark,
      title: "JFF",
      description:
        "We feel fortunate to work with you. Among the various third-party companies we've collaborated with, your team stands out for its unwavering support and transparency. Your timely responses and feedback have kept our team happy and productive. We've gained valuable technical and process insights from our collaboration and hope to continue learning from you. Your team members, both front and back-end, are highly supportive and technically proficient. We consider ourselves lucky to have clients and a team like yours; you truly feel like an extension of our own team. Thank you for your constant support",
      subtitle: "CMO of Visionary Apps",
    },
  ],
  clients: [
    {
      image: techsofy,
      title: "Techsophy",
      description:
        "Thank you for helping us source a few of our critical roles over the last six months. It enabled us to mitigate some of the program risks we were dealing with. I am looking forward to continued assistance and want to grow this relationship further in other work areas, thus enabling us to reach new heights..",
      subtitle: "HR of Techsophy",
    },
    {
      image: ConstructN,
      title: "ConstructN.ai",
      description:
        "We are delighted to provide a testimonial for the Hiringhood team. As a startup, we faced challenges in employee recruitment, but Hiringhood understood our product's needs, provided prompt and professional service, and helped us close positions in less than 3 weeks. Their knowledgeable team, efficient follow-ups, and tailored approach make them a valuable asset for any business seeking top-notch recruitment services.",
      subtitle: "HR of ConstructAI",
    },
    {
      image: aheadrace,
      title: "Aheadrace",
      description:
        "As a head of recruitment, I have found that Hiringhood invests a lot of time to make the process extremely precise, accurate and tech-enabled to obtain spectacular results in short periods of time. We collaborated to fill roles requiring expertise in WebGL, React JS, we were struggling to fill this role and hiringhood was able to fill this position within two weeks to our surprise.",
      subtitle: "HR of Aheadrace",
    },
  ],
  jobseekers: [
    {
      image: Vivek,
      title: "Vivek Kashyap",
      description:
        "I was fortunate to have Hiringhood as my representative in my interview and onboarding process. Their service, help, and support are outstanding. They take away all your stress and handle all regular follow-ups for you. They truly dedicate their time and make the end-to-end process from interview to onboarding easy and seamless.",
      subtitle: "Employee at Aheadrace",
    },
    {
      image: Pradeep,
      title: "Pradeep Borugadda",
      description:
        "Constructn.ai, particularly Pradeep Borugadda in the ML designation, played a vital role in my job search journey. Hiringhood's exceptional service provided personalized guidance and support, leading to securing a fantastic new job. Their dedication to understanding my unique skills and career goals, coupled with clear and prompt communication, made the process stress-free. Thanks to Hiringhood, I landed my dream job, and I highly recommend their services for anyone seeking career advancement.",
      subtitle: "Employee at ConstructN.ai",
    },
    {
      image: NirmalVarma,
      title: "Nirmal Varma",
      description:
        "I am writing to express my gratitude to Hiringhood for their invaluable assistance in securing a job with Techsophy. The experience with Hiringhood has been consistently professional and seamless. From the initial discussions about available roles to the finalisation of the employment offer, the entire process was characterised by clear and effective communication. Based on my personal experience, I wholeheartedly recommend Hiringhood to anyone seeking professional assistance in their job search journey. Thank you for your exceptional service.",
      subtitle: "Employee at Techsophy",
    },
  ],
};

export const successstories = {
  construction: {
    title:
      "Success Story: How Hiringhood Transformed ConstructN's Hiring Process",
    description: `ConstructN.AI, a leading company in the construction industry, was facing a significant challenge in finding the perfect candidate for the position of Lead Machine Learning (ML). Despite exhaustive efforts in the market, they were unable to locate the ideal fit for their requirements. Frustrated by their unsuccessful attempts, they turned to Hiringhood for assistance. 

      Upon contacting Hiringhood, ConstructN.AI was impressed by the swift response and efficiency of our team. Within a day, an agreement was reached, and Hiringhood immediately initiated the sourcing process. Leveraging our extensive network and advanced algorithms, we quickly identified numerous potential candidates, among whom ConstructN.AI found several high-quality profiles.
      
      Among the profiles presented by Hiringhood, ConstructN.AI identified Pradeep Borugadda as the standout candidate. Despite the challenges inherent in filling a senior-level position, Pradeep impressed ConstructN.AI throughout the interview process, which spanned three weeks. He not only met but exceeded their expectations, showcasing his expertise by successfully clearing an AI and machine learning-based case study.
      
      The rigorous nature of the interview process, comprising multiple rounds and assessments, highlighted the importance of Hiringhood's role in facilitating the recruitment process. As ConstructN.AI's trusted partner, we provided continuous support and delivered exceptional candidates tailored to their specific needs.
      
      Pradeep's onboarding process was seamless, thanks to Hiringhood's meticulous attention to detail and commitment to excellence. Within a short span of 2-3 weeks, from the initial sourcing phase to the final selection, ConstructN.AI successfully onboarded Pradeep as their Lead ML, fulfilling a critical requirement within their organization.
      
      ConstructN.AI's experience with Hiringhood exemplifies the power of collaboration and the transformative impact of leveraging advanced recruitment solutions. By entrusting their hiring needs to Hiringhood, ConstructN.AI not only found a highly qualified candidate but also achieved significant cost savings, as we successfully closed the candidate within their budget constraints.
      
      This success story underscores Hiringhood's dedication to delivering exceptional results and reaffirms our commitment to empowering organizations to build high-performing teams efficiently and effectively. We are proud to have played a pivotal role in ConstructN.AI's success story and look forward to continuing to exceed expectations in all our future endeavours.
      `,
  },
  aheadrace: {
    title:
      "Success Story: Fulfilling Ahead Race's Rare Hiring Needs with Precision",
    description: `Ahead Race, a pioneering company at the forefront of technological innovation, faced a unique challenge in their quest for talent. Their requirement for individuals proficient in WebGL and React, a combination known for its rarity, posed a significant hurdle in their hiring process. With such specialized skills in high demand and low supply, Ahead Race struggled to find suitable candidates across Pan India.

      Recognizing the urgency and complexity of their requirement, Ahead Race turned to Hiringhood, a trusted partner they had empanelled with long ago. Upon engaging with Hiringhood, Ahead Race was met with unparalleled efficiency and expertise. Through a thorough JD discovery call, Hiringhood meticulously understood Ahead Race's requirements and swiftly began the search process.
      
      Despite the rarity of the skill set, Hiringhood's extensive network and advanced algorithms enabled them to identify a pool of 50 potential candidates across India. Among this select group, only 8 candidates possessed the perfect blend of skills in WebGL and React, and crucially, were available to join immediately.
      
      Understanding the time sensitivity of the situation, Hiringhood expedited the interview process, ensuring a seamless experience for both Ahead Race and the candidates. Within a remarkably short span of 1-2 weeks, Ahead Race conducted thorough assessments, ultimately selecting the ideal candidate from the list provided by Hiringhood.
      
      Not only did Hiringhood successfully meet Ahead Race's stringent criteria, but they also ensured that the selected candidate fit within Ahead Race's budget constraints. This meticulous attention to detail and commitment to client satisfaction further solidified the partnership between Ahead Race and Hiringhood.
      
      The successful onboarding of the chosen candidate within the week marked a significant milestone for Ahead Race, enabling them to swiftly fill a crucial role within their organization. The seamless recruitment process, coupled with the exceptional quality of the candidate sourced by Hiringhood, left Ahead Race thoroughly impressed and satisfied.
      
      This success story serves as a testament to Hiringhood's unparalleled ability to navigate complex hiring challenges and deliver exceptional results. By leveraging their expertise and innovative approach to recruitment, Hiringhood not only met but exceeded Ahead Race's expectations, solidifying their position as a trusted partner in talent acquisition.
      
      Ahead Race's experience with Hiringhood exemplifies the power of collaboration and underscores the transformative impact of leveraging advanced recruitment solutions to address even the most challenging hiring needs. As Ahead Race continues to innovate and push boundaries, Hiringhood stands ready to support their talent acquisition journey every step of the way.
      `,
  },
  proarch: {
    title:
      "Success Story: Rapidly Filling Proarch's Architect Role with Hiringhood's Expertise",
    description: `Proarch, a leading company with a global footprint, faced a pressing need to fill a pivotal Architect role, overseen by a hiring manager based in the UK. Having forged a longstanding partnership with Hiringhood, Proarch entrusted their recruitment process to our expertise. We swiftly organized a comprehensive JD discovery call, meticulously outlining the requirements over a 45-minute discussion, ensuring complete clarity.
      
      Despite receiving numerous profiles from our network of recruiters, none perfectly aligned with Proarch's exacting standards. Undeterred, we engaged in detailed discussions with our recruiters, reiterating the specific architectural patterns and skill sets sought by Proarch. This strategic refinement process led to the identification of several promising candidates who matched Proarch's criteria.
        
      Among the candidates, Mahesh Bhat stood out, impressing Proarch's hiring team across multiple rounds of assessment, including L1, L2, and L3 interviews. Despite the complexity of the role and the stringent evaluation process, Hiringhood ensured a seamless coordination, expediting the recruitment timeline.
        
      Within an astonishing 30 days, from the initial engagement to the final selection, Hiringhood successfully filled the Architect position for Proarch. This achievement was particularly notable as Proarch had previously struggled to fill the role, even with their internal TA team's efforts. Proarch's hiring manager expressed profound satisfaction with Hiringhood's efficiency and expertise, lauding our ability to achieve what had previously seemed unattainable.
        
      Proarch's success story serves as a testament to Hiringhood's commitment to delivering exceptional results and exceeding client expectations. Our ability to navigate complex hiring challenges and provide tailored solutions underscores the value of our partnership with Proarch.
        
      As Proarch continues to innovate and expand its operations, Hiringhood stands ready to support their talent acquisition needs, ensuring they maintain a competitive edge in the market. We look forward to continuing our journey of success together, driving growth and excellence in Proarch's workforce.
      `,
  },
};

export const awards = [
  {
    title: "Our Awards",
    image: risingStar,
    heading: "Rising Star Startup Award",
    description:
      "ITTL - India Tech Talent League by MyAnotomy Integration has awarded the Startup Excellence Awards 2023",
  },
  {
    title: "Our Awards",
    image: tSpark,
    heading: "T-Spark Award",
    description:
      "Empowering Telangana's innovation ecosystem since 2017, driving inclusive growth and entrepreneurship under state leadership",
  },
  {
    title: "Our Awards",
    image: indiaStartup,
    heading: "Winner of ISF 2023",
    description:
      "India Startup Festival catalyses entrepreneurship, aligning with GOI initiatives, drawing 10,000+ startups to Bengaluru, driving innovation for an Atmanirbhar Bharat.",
  },
  {
    title: "Events & News",
    image: tAngel,
    heading: "T - Angel Cohort - 5",
    description:
      "T-Angel, a 100-day program for revenue-generating startups to secure their first investment, launched today at T-Hub with Cohort No. 5, featuring 13 participating startups.",
  },
  {
    title: "Events & News",
    image: idream,
    heading: "iDream Entrepreneur talks",
    description:
      "Big news at Hiringhood! Introducing Recruiter ML: a turbocharged virtual recruiter finding perfect matches fast. Our CEO, Sarath Babu Yandrapalli, spilled all on iDream",
  },
  {
    title: "Events & News",
    image: idream2,
    heading: "Eenadu Article",
    description:
      "Our CEO, Sarath Babu Yandrapalli, Spilled on Eenadu regarding accelerated hiring to employers through hiringhood",
  },
  {
    title: "News & Events",
    heading: "Seed Round Closed",
    image: seedRound,
    description:
      "Seed round funding has been provided to Hiringhood by Venakan Info Solutions",
  },
  {
    title: "News & Events",
    heading: "Participated in Hysea",
    image: hysea,
    description:
      "The Hyderabad Software Enterprise Association conducted an event at Novotel on February 14, 2024.",
  },
];
