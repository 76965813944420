import { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "../../assets/home-page/Home-1.png";
import HiringContest from "../../assets/home-page/HiringContests.png";
import HotList from "../../assets/home-page/HotList.png"
import About from "../../assets/home-page/AboutUs.png";

import Signin from "../../assets/home-page/SignIn.png";
import HiringHoodLogo from "../../assets/home-page/HiringHoodLogo.png";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setActiveLink } from "../../redux/actions/menu";
import {
  HOME,
  CONTESTS,
  ABOUT_US,
  RECRUITERS,
  EMPLOYERS,
  HOTLIST,
  LOGIN,
} from "../../utils/constants/routes";
import styles from "./style.module.scss";
import Hotlist from "../Hotlist";

const showEmployersMenu = process.env.REACT_APP_SHOW_EMPLOYERS_MENU;
const showRecruitersMenu = process.env.REACT_APP_SHOW_RECRUITERS_MENU;
const showPartnersMenu = process.env.REACT_APP_SHOW_PARTNERS_MENU;

const headerMenus = [
  {
    label: "Home",
    link: HOME,
  },
  {
    label: "Hiring Contests",
    link: CONTESTS,
  },
  {
    label: "Hot-list",
    link: HOTLIST,
  },
  {
    label: "About Us",
    link: ABOUT_US,
  },
];

const drawerMenus = [
  {
    label: "Home",
    link: HOME,
    path: Home,
  },
  {
    label: "Hiring Contests",
    link: CONTESTS,
    path: HiringContest,
  },
  {
    label: "Hot-list",
    link: HOTLIST,
    path: HotList,
  },
  {
    label: "About Us",
    link: ABOUT_US,
    path: About,
  },
];

const Menu = () => {
  const activeLink = useSelector(
    (state: RootStateOrAny) => state.menu.activeLink
  );
  const dispatch = useDispatch();

  const onMenuItemClick = (label: string) => {
    dispatch(setActiveLink(label));
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={styles["menu-items"]}>
        {headerMenus.map((menu, index) => (
          <div
            key={index}
            className={`${styles["menu-item"]} ${
              activeLink === menu.label ? styles["menu-item-active"] : ""
            }`}
          >
            {menu.link && (
              <Link
                to={menu.link}
                className={styles["menu-anchor"]}
                onClick={() => onMenuItemClick(menu.label)}
              >
                {menu.label}
              </Link>
            )}
            {!menu.link && menu.label}
          </div>
        ))}
      </div>
      <div
        className={styles["menu-icon"]}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuIcon sx={{ fontSize: 30 }} />
      </div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            borderRadius: "36px 0px 0px 16px",
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{ width: 270 }}
          role="presentation"
          onClick={() => {
            setOpen(false);
          }}
          onKeyDown={() => {
            setOpen(false);
          }}
        >
          <Box
            component="img"
            sx={{
              height: 60,
              width: 150,
            }}
            src={HiringHoodLogo}
            alt="HiringHood"
          />
          <Divider sx={{ backgroundColor: "#4D6CD9", marginY: 2 }} />
          {drawerMenus.map((menu, index) => (
            <Link
              key={index}
              to={menu.link}
              className={styles["menu-anchor"]}
              onClick={() => onMenuItemClick(menu.label)}
            >
              <div
                className={styles["mobile-menu-item"]}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4D6CD9",
                }}
              >
                <img
                  src={menu.path}
                  alt={menu.label}
                  style={{ height: 20, marginRight: 10 }}
                />
                <span>{menu.label}</span>
              </div>
            </Link>
          ))}

    


          <Button
            component={Link}
            to={LOGIN}
            className={styles["menu-anchor"]}
           
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4D6CD9",
              color:"white",
              justifyContent: "space-around",
              marginTop:15,
              width:190
            }}
          >
            <span>Sign In</span>
            <img src={Signin} />
          </Button>
   
        
        </Box>
      </Drawer>
    </>
  );
};

export default Menu;
