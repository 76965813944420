import Accordion from "../Accordion";
import "./styles.css";
import axios from "axios";
import { getLocalStorage } from "../../utils/storage-manager";
import { useEffect, useState } from "react";
import { CONTEST_API } from "../../utils/constants/urls";

const FAQ = (props) => {
  const { hideTitle = false, faq = [] } = props;
  interface HttpResponse {
    data: {
      attributes: {
        google_sheet_embed_faqs: string;
      };
    };
  }
  const [googleLink, Setgooglelink] = useState("");
  const id = getLocalStorage("contestId", null);
  const apiUrl = `${CONTEST_API}/${id}`;

  useEffect(() => {
    axios
      .get<HttpResponse>(apiUrl)
      .then((response) => {
        const googleSheetLink =
          response.data.data.attributes.google_sheet_embed_faqs;
        Setgooglelink(googleSheetLink);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
        return [];
      });
  }, []);

  return (
    <div className="faq-container">
      {!hideTitle && <p className="faq-title">Frequently Asked Questions</p>}
      <div className="faq-accordion-wrapper">
        <iframe src={googleLink} />
        {faq &&
          faq.length > 0 &&
          faq.map((item: any) => {
            return (
              <>
                {item.question && (
                  <div key={item.id} className="faq-accordion-container">
                    <Accordion title={item.question}>
                      <div
                        className="faq-answer-container"
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></div>
                    </Accordion>
                  </div>
                )}
              </>
            );
          })}
      </div>
      {faq.length === 0 && (
        <p className="faq-no-faq">There are no FAQs configured</p>
      )}
    </div>
  );
};

export default FAQ;
