import { Link } from "react-router-dom";
import ContestDetail from "../ContestDetail";
import Card from "../Card";
import Button from "../Button";
import CashReward from "../CashReward";
import ContestIcons from "../ContestIcons";
import companyImage from "../../assets/company.svg";
import experienceImage from "../../assets/experience.svg";
import locationImage from "../../assets/location.svg";
import positionsImage from "../../assets/positions.svg";
import ctcImage from "../../assets/ctc.svg";
import matchingProfilesImage from "../../assets/matching-profiles.svg";
import quotaImage from "../../assets/quota.svg";
import calendarImage from "../../assets/calendar-background.svg";
import { getImageForTag, getImageForBadge } from "../../utils/util";
import { CONTEST_DETAILS } from "../../utils/constants/routes";
import "./styles.css";
import Divider from '@mui/material/Divider';

interface ContestDetails {
  id: string;
  employmentType: string;
  jobTitle: string;
  cashReward: string;
  company: string;
  experience: string;
  locations: string;
  positions: string;
  ctc: string;
  contestCreatedDuration: string;
  quota: number;
  tag: string;
  badge: string;
  matchingProfiles: string;
  totalQuota: number;
  daysLeft: number;
  iconsToShow: Array<string>;
}

interface Props {
  contestDetails: ContestDetails;
}

const renderDetails = (image: string, text: any, tooltip: string) => {
  return (
    <div className="contest-lists-detail-container">
      <img src={image} alt="Icon" title={tooltip} />
      <p className="contest-lists-detail-text">{text}</p>
    </div>
  );
};

const ContestListCard: React.FC<Props> = (props) => {
  const { contestDetails } = props;
  const { iconsToShow = [] } = contestDetails;
  const tag = getImageForTag(contestDetails.tag);

  return (
    <div className="contest-width">
      <Card>
        <div className="contest-list-card-container">
          <div className="contest-list-card-left">
           
            <div className="contest-list-card-job-post-duration">
              {tag && <img src={tag} alt="Tag" />}
              <p>{contestDetails.contestCreatedDuration}</p>
            </div>
            
            <div className="badgeDetails">
            {getImageForBadge(contestDetails.badge) && (
              <img
                src={getImageForBadge(contestDetails.badge)}
                alt="Badge"
                className="contest-list-badge"
              />
            )}
            <div className="contest-list-card-top-icons-container">
              <ContestIcons id={contestDetails.id} iconsToShow={iconsToShow} />
            </div>
            <div className="contest-list-details-container">
              <ContestDetail contestDetails={contestDetails} />
            </div>
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="contest-list-card-right">
            <div className="contest-list-card-icons-container">
              <CashReward amount={contestDetails.cashReward} />
              <div className="contest-list-card-icon-stats-container">
                {contestDetails.matchingProfiles && (
                  <div className="contest-list-card-icon-container">
                    <div className="contest-list-card-icon-text-container">
                      <img
                        src={matchingProfilesImage}
                        alt="Matching Profiles"
                      />
                      <p className="contest-list-card-icon-text">
                        {contestDetails.matchingProfiles}
                      </p>
                    </div>
                    <p className="contest-list-card-icons-container-text">
                      Matching Profiles
                    </p>
                  </div>
                )}
                <div className="contest-list-card-icon-container">
                  <div className="contest-list-card-icon-text-container">
                    <img src={quotaImage} alt="Total Quota" />
                    <p className="contest-list-card-icon-text">
                      {contestDetails.totalQuota}
                    </p>
                  </div>
                  <p className="contest-list-card-icons-container-text">
                    Total Quota
                  </p>
                </div>
                <div className="contest-list-card-icon-container">
                  <div className="contest-list-card-icon-text-container">
                    <img src={calendarImage} alt="Days Left" />
                    <p className="contest-list-card-icon-text">
                      {contestDetails.daysLeft}
                    </p>
                  </div>
                  <p className="contest-list-card-icons-container-text">
                    Days Left
                  </p>
                </div>
              </div>
            </div>
            <Divider/>
            <div className="contest-list-section-container">
            <Divider orientation="horizontal"/>
              <div className="contest-list-section">
                {renderDetails(companyImage, contestDetails.company, "Company")}
              </div>
              <div className="contest-list-section">
                {renderDetails(
                  experienceImage,
                  contestDetails.experience,
                  "Experience"
                )}
                {renderDetails(
                  positionsImage,
                  contestDetails.positions,
                  "Positions"
                )}
              </div>
              <div className="contest-list-section">
                {renderDetails(ctcImage, contestDetails.ctc, "CTC")}
                {renderDetails(
                  locationImage,
                  contestDetails.locations,
                  "Locations"
                )}
              </div>
            </div>
            <Link
              to={CONTEST_DETAILS.replace(":id", contestDetails.id)}
              className="contest-list-card-join-hunt"
            >
              <Button
                label="Join Hunt"
                className="contest-list-card-join-hunt-button"
              />
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContestListCard;
