import React, { useState}  from 'react';
import "./styles.scss";
import "./NewOnboadForm.css";
import Card from "../Card";
import logo from "../../assets/logo-color.svg";
import PersonalDetails from '../NewOnboardForms/PersonalDetailsForm';
import Professional from '../NewOnboardForms/ProfessionalDetailsForm';
import ExperienceDetails from '../NewOnboardForms/ExperienceDetails';
import Verification from '../NewOnboardForms/VerificationDetails';
import styles from "../Header/style.module.scss";
import TermsToUse from '../NewOnboardForms/TermsAndConditions';
import { InviteStaff } from '../NewOnboardForms/InviteStaff';
import Copyright from "../Copyright";


const NewOnboardForm=()=> {

   const [activeForm, setActiveForm] = useState('personal');
   const [form, setForm] = useState({
    fullName: "",
    designation: "",
    email: "",
    officialEmail: "",
    mobile: "",
    companyName: "",
    address: "",
  });

   const handleActiveFormChange = (newActiveForm) => {
    setActiveForm(newActiveForm);
   };
    
const greenEmoji = <span className="box"><span className="tick">&#x2713;</span></span>;
const blackEmoji = <span className="blackBox"><span className="tick">&#x2713;</span></span>; 
const activeForm1 =  activeForm === 'personal' || activeForm === 'professional'|| activeForm === 'ExperienceDetails' ||activeForm === 'Verification' ||activeForm === 'TermsToUse' || activeForm === 'InviteStaff' ; 
const activeForm2 = activeForm === 'professional'|| activeForm === 'ExperienceDetails' || activeForm === 'Verification' || activeForm === 'TermsToUse' || activeForm === 'InviteStaff';
const activeForm3 =   activeForm === 'ExperienceDetails' || activeForm === 'Verification' || activeForm === 'TermsToUse' || activeForm === 'InviteStaff';
const activeForm4 = activeForm === 'Verification' || activeForm === 'TermsToUse' || activeForm === 'InviteStaff' ;
const activeForm5 = activeForm === 'TermsToUse' || activeForm === 'InviteStaff' ;
const activeForm6 = activeForm === 'InviteStaff' ;

const  percentage = (activeForm) => {
  switch (activeForm) {
    case 'personal':
      return '20%';
    case 'professional':
      return '40%';
    case 'ExperienceDetails':
      return '60%';
    case 'Verification':
      return '80%';
    case 'TermsToUse':
      return '100%';
    // case 'InviteStaff':
    //   return '100%';
  }
}
// const formSteps = [
//   'personal',
//   'professional',
//   'ExperienceDetails',
//   'Verification',
//   'TermsToUse',
//   'InviteStaff',
// ];

// // Find the index of the currently active form
// const currentIndex = formSteps.indexOf(activeForm);

// // Now generate your list elements dynamically
// const listItems = formSteps.map((formStep, index) => {
//   // The form step is clickable if its index is less or equal to than the index of the currently active form
//   const isClickable = index <= currentIndex;

//   const formStepActive = activeForm === formStep;

  return (
    <div className="onboard-wrapper">
      <Card style={{borderRadius:'2px'}}>
        <div className="onboard-container onboard-card-container-height">
        <div className="left-panel">
          <div className="card">
          <div className='Logo'>
              <img src={logo} alt="Logo" className="logo" />
          </div>
             <ul style={{listStyleType:'none'}} >
               
                <li style={{marginBottom:'30px'}} > 
                          <h2 className='listElements' style={{ color: activeForm1 ? 'white' : 'gray' }} > 
                          <span style={{ marginRight: '10px' }} >{activeForm1 ? greenEmoji : blackEmoji} </span>
                          Personal Details</h2>
                      <p className={`description ${activeForm1 ? 'active' : ''}`} >Tell us more about you</p>
                </li>


                <li style={{marginBottom:'30px' }} >
                     <h2 className='listElements' style={{ color: activeForm2   ? 'white' : 'gray' }}>
                     <span style={{ marginRight: '10px', }} >{activeForm2 ? greenEmoji : blackEmoji} </span>
                      Professional</h2>
                 <p className={`description ${activeForm2 ? 'active' : ''}`} >Tell us about your profession</p>
                </li>


                <li style={{marginBottom:'30px'}} > 
                     <h2 className='listElements' style={{ color: activeForm3 ? 'white' : 'gray'}}>
                     <span style={{ marginRight: '10px', }} >{activeForm3 ? greenEmoji : blackEmoji} </span>
                      Experience Details </h2>              
                <p className={`description ${activeForm3 ? 'active' : ''}`} >Tell us how experienced you are</p>
                </li>


                <li style={{marginBottom:'30px'}}>                 
                     <h2 className='listElements' style={{ color: activeForm4 ? 'white' : 'gray' }}>
                         <span style={{ marginRight: '10px', }} >{activeForm4 ? greenEmoji : blackEmoji} </span>
                         Verification
                      </h2>
                  <p className={`description ${activeForm4 ? 'active' : ''}`}>Help us to verify you</p>
                </li>


                <li style={{marginBottom:'30px'}}>                 
                     <h3 className='listElements' style={{ color: activeForm5 ? 'white' : 'gray'}}>
                       <span style={{ marginRight: '10px', }} >{activeForm5 ? greenEmoji : blackEmoji} </span>
                        Terms & Privacy Policies</h3>
                  <p className={`description ${activeForm5 ? 'active' : ''}`} >Check out our T&C Policies</p>
                </li>


                {/* <li style={{marginBottom:'30px'}}>                
                   <h2 className='listElements' style={{ color: activeForm6 ? 'white' : 'gray'}}>
                   <span style={{ marginRight: '10px', }} >{activeForm6 ? greenEmoji : blackEmoji} </span>
                    Invite Staff</h2>
                 <p className={`description ${activeForm6 ? 'active' : ''}`} >Add your staff to manage</p>
                </li> */}


                <div className="meter">
                    <div className="progress" style={{ width: percentage(activeForm) }}>
                        <span className="percentage">{percentage(activeForm)}</span>
                   </div>
                </div>
  
             </ul>
           </div>
        </div>

        <div className="right-panel">
        
          {activeForm === 'personal' && <PersonalDetails onActiveFormChange={handleActiveFormChange} form={form} setForm={setForm}/>}
          {activeForm === 'professional' && <Professional onActiveFormChange={handleActiveFormChange}/>}
          {activeForm === 'ExperienceDetails' && <ExperienceDetails onActiveFormChange={handleActiveFormChange}/>}
          {activeForm === 'Verification' && <Verification onActiveFormChange={handleActiveFormChange}/>}
          {activeForm === 'TermsToUse' && <TermsToUse onActiveFormChange={handleActiveFormChange}/>}
          {/* {activeForm === 'InviteStaff' && <InviteStaff onActiveFormChange={handleActiveFormChange}/>} */}
          
          
        </div>
       
        </div>
        
    </Card>
    <Copyright />
    </div>
    );
};
export default NewOnboardForm;
