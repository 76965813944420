import wantedImage from "../../assets/wanted.svg";
import contestTypeImage from "../../assets/contest-type.png";
import "./styles.css";

interface ContestDetails {
  id: string;
  contestCreatedDuration?: string;
  employmentType: string;
  jobTitle: string;
  cashReward: string;
  company?: string;
  experience?: string;
}

interface Props {
  contestDetails: ContestDetails;
}

const ContestDetail: React.FC<Props> = (props) => {
  const { contestDetails } = props;

  return (
    <div>
      <div className="contest-detail-container">
        <img
          className="contest-detail-wanted-image"
          src={wantedImage}
          alt="Wanted"
        />
        {/* <img
          className="contest-detail-type-image"
          // src={contestTypeImage}
          alt="Menu"
        /> */}
        {/* <p className="contest-detail-job-type">
          {contestDetails.employmentType}
        </p> */}
        <div
          style={{
            minWidth: "12rem",
            marginTop: "0.75rem",
          }}
        >
          <p className="contest-detail-job-title">{contestDetails.jobTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default ContestDetail;
