// import { useState, useMemo } from "react";
// import EmployerCard from "../EmployerCard";
// import EmployerListCard from "../EmployerListCard";
// import Pagination from "../Pagination";
// import gridViewImage from "../../assets/grid-view.png";
// import gridViewActiveImage from "../../assets/grid-view-active.svg";
// import listViewImage from "../../assets/list-view.png";
// import listViewActiveImage from "../../assets/list-view-active.svg";
// import SelectValidator from "../SelectValidator";
// import EmployerFilters from "../EmployerFilters";
// import "./styles.css";

// interface EmployerDetails {
//   id: number;
//   name: string;
//   coverImage: string;
//   logo: string;
//   rating: number;
//   reviews: number;
//   openContests: number;
//   contestsConducted: number;
//   joiningDate;
// }

// interface Props {
//   employers: EmployerDetails[];
//   numberOfEmployersPerPage?: number;
//   employersData: any;
//   onReset?: any;
// }

// const Employers: React.FC<Props> = (props) => {
//   const {
//     employers,
//     numberOfEmployersPerPage = 12,
//     employersData,
//     onReset,
//   } = props;
//   const [perPage, setPerPage] = useState(numberOfEmployersPerPage);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [gridView, setGridView] = useState(true);

//   const [sortedEmployers, setSortedEmployers] = useState([...employers]);

//   const pageData = useMemo(() => {
//     const firstPageIndex = (currentPage - 1) * perPage;
//     const lastPageIndex = firstPageIndex + perPage;
//     return sortedEmployers.slice(firstPageIndex, lastPageIndex);
//   }, [currentPage, sortedEmployers, perPage]);

//   const sortOptions = [
//     "Newest",
//     "Oldest",
//     "Rating",
//     "Contests Open",
//     "Contests Conducted",
//   ];

//   const [activeSort, setActiveSort] = useState(sortOptions[0]);

//   const numberPerPage = ["12", "24", "36", "48"];

//   const onPageChange = (page: number) => {
//     setCurrentPage(page);
//     window.scrollTo(0, 0);
//   };

//   const handlePerPage = (event) => {
//     setPerPage(Number(event.target.value));
//   };

//   const handleSort = (sortedEmployers, value) => {
//     let updatedEmployers = sortedEmployers;
//     if (value === "Newest") {
//       updatedEmployers = updatedEmployers.sort(function (employer1, employer2) {
//         return employer2.joiningDate - employer1.joiningDate;
//       });
//     }
//     if (value === "Oldest") {
//       updatedEmployers = updatedEmployers.sort(function (employer1, employer2) {
//         return employer1.joiningDate - employer2.joiningDate;
//       });
//     }
//     if (value === "Rating") {
//       updatedEmployers.sort(function (employer1, employer2) {
//         return employer2.rating - employer1.rating;
//       });
//     }
//     if (value === "Contests Open") {
//       updatedEmployers.sort(function (employer1, employer2) {
//         return employer2.openContests - employer1.openContests;
//       });
//     }
//     if (value === "Contests Conducted") {
//       updatedEmployers.sort(function (employer1, employer2) {
//         return employer2.contestsConducted - employer1.contestsConducted;
//       });
//     }

//     setSortedEmployers([...updatedEmployers]);
//   };

//   const onFiltersChanged = (list) => {
//     handleSort(list, activeSort);
//   };

//   return (
//     <div className="employers-container">
//       <div className="employers-title-container">
//         <p className="employers-title">Employer Directory</p>
//         <div className="employers-sort-container">
//           <img
//             src={gridView ? gridViewActiveImage : gridViewImage}
//             alt="Grid View"
//             onClick={() => {
//               setGridView(true);
//             }}
//             className="employers-icon"
//           />
//           <img
//             src={gridView ? listViewImage : listViewActiveImage}
//             alt="List View"
//             onClick={() => {
//               setGridView(false);
//             }}
//             className="employers-icon employers-list-view-icon"
//           />
//           <div className="employers-sort">
//             <SelectValidator
//               // eslint-disable-next-line @typescript-eslint/no-empty-function
//               register={() => {}}
//               label="Sort by"
//               list={sortOptions}
//               value={sortOptions[0]}
//               callback={(e) => {
//                 setActiveSort(e.target.value);
//                 handleSort(sortedEmployers, e.target.value);
//               }}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="employers-cards-container">
//         <div className="employers-filters">
//           <EmployerFilters
//             employersData={employersData}
//             onFiltersChanged={onFiltersChanged}
//             onReset={onReset}
//           />
//         </div>
//         <div className="employers-cards-list-container">
//           {pageData && pageData.length === 0 && (
//             <p className="employers-no-employers">
//               There are currently no employers on board
//             </p>
//           )}
//           {gridView && (
//             <div className="employers-employers-container">
//               {pageData &&
//                 pageData.length > 0 &&
//                 pageData.map((employerDeatils: any, index: number) => {
//                   return (
//                     <EmployerCard
//                       employerDeatils={employerDeatils}
//                       key={index}
//                     />
//                   );
//                 })}
//             </div>
//           )}
//           {!gridView &&
//             pageData &&
//             pageData.length > 0 &&
//             pageData.map((employerDeatils: any, index: number) => {
//               return (
//                 <div className="employers-list-card" key={index}>
//                   <EmployerListCard
//                     employerDeatils={employerDeatils}
//                     key={index}
//                   />
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//       <div className="employers-per-page-container">
//         <div className="employers-per-page">
//           <SelectValidator
//             // eslint-disable-next-line @typescript-eslint/no-empty-function
//             register={() => {}}
//             label=""
//             list={numberPerPage}
//             value={numberPerPage[0]}
//             callback={handlePerPage}
//             disabled={currentPage !== 1}
//           />
//         </div>
//         <p className="employers-per-page-text">Per Page</p>
//       </div>
//       <Pagination
//         className="employers-pagination-container"
//         currentPage={currentPage}
//         totalCount={sortedEmployers.length}
//         pageSize={perPage}
//         onPageChange={onPageChange}
//       />
//     </div>
//   );
// };

// export default Employers;

import { useState, useMemo } from "react";
import EmployerCard from "../EmployerCard";
import EmployerListCard from "../EmployerListCard";
import Pagination from "../Pagination";
import gridViewImage from "../../assets/grid-view.png";
import gridViewActiveImage from "../../assets/grid-view-active.svg";
import listViewImage from "../../assets/list-view.png";
import listViewActiveImage from "../../assets/list-view-active.svg";
import SelectValidator from "../SelectValidator";
import EmployerFilters from "../EmployerFilters";
import { Drawer, Button } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "./styles.css";

interface EmployerDetails {
  id: number;
  name: string;
  coverImage: string;
  logo: string;
  rating: number;
  reviews: number;
  openContests: number;
  contestsConducted: number;
  joiningDate: number;
}

interface Props {
  employers: EmployerDetails[];
  numberOfEmployersPerPage?: number;
  employersData: any;
  onReset?: any;
}

const Employers: React.FC<Props> = (props) => {
  const { employers, numberOfEmployersPerPage = 12, employersData, onReset } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [gridView, setGridView] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);
  const [sortedEmployers, setSortedEmployers] = useState(
    employers && employers.length > 0
      ? [...employers.sort((employer1, employer2) => employer2.joiningDate - employer1.joiningDate)]
      : []
  );
  const [activeSort, setActiveSort] = useState("Newest");

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * numberOfEmployersPerPage;
    const lastPageIndex = firstPageIndex + numberOfEmployersPerPage;
    return sortedEmployers.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sortedEmployers, numberOfEmployersPerPage]);

  const sortOptions = ["Newest", "Oldest", "Rating", "Contests Open", "Contests Conducted"];

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const toggleDrawer = () => {
    setOpenFilters((prevState) => !prevState);
  };

  const handleSort = (sortedEmployers, value) => {
    let updatedEmployers = sortedEmployers;
    if (value === "Newest") {
      updatedEmployers = updatedEmployers.sort((employer1, employer2) => employer2.joiningDate - employer1.joiningDate);
    }
    if (value === "Oldest") {
      updatedEmployers.sort((employer1, employer2) => employer1.joiningDate - employer2.joiningDate);
    }
    if (value === "Rating") {
      updatedEmployers.sort((employer1, employer2) => employer2.rating - employer1.rating);
    }
    if (value === "Contests Open") {
      updatedEmployers.sort((employer1, employer2) => employer2.openContests - employer1.openContests);
    }
    if (value === "Contests Conducted") {
      updatedEmployers.sort((employer1, employer2) => employer2.contestsConducted - employer1.contestsConducted);
    }
    setSortedEmployers([...updatedEmployers]);
  };

  const onFiltersChanged = (list) => {
    handleSort(list, activeSort);
  };

  return (
    <div className="employers-container">
      <div className="employers-title-container">
        <p className="employers-title">Employer Directory</p>
        <div className="employers-sort-container">
          <img
            src={gridView ? gridViewActiveImage : gridViewImage}
            alt="Grid View"
            onClick={() => setGridView(true)}
            className="employers-icon hidden-mobile"
          />
          <img
            src={gridView ? listViewImage : listViewActiveImage}
            alt="List View"
            onClick={() => setGridView(false)}
            className="employers-icon employers-list-view-icon hidden-mobile"
          />
          <div className="employers-sort">
            <SelectValidator
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              register={() => {}}
              
              list={sortOptions}
              value={activeSort}
              callback={(e) => {
                setActiveSort(e.target.value);
                handleSort(sortedEmployers, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="filters-button">
        <Button
          variant="outlined"
          endIcon={<FilterAltOutlinedIcon />}
          onClick={toggleDrawer}
        >
          Filters
        </Button>
      </div>
      <div className="employers-wrapper-container">
        <Drawer anchor={"left"} open={openFilters} onClose={toggleDrawer}>
          <EmployerFilters
            employersData={employersData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </Drawer>
        <div className="employers-filters-container">
          <EmployerFilters
            employersData={employersData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </div>
        {pageData && pageData.length > 0 && (
          <>
            <div>
              {gridView && (
                <div className="employers-employers-container">
                  {pageData.map((employerDetails: any, index: number) => (
                    <EmployerCard employerDeatils={employerDetails} key={index} />
                  ))}
                </div>
              )}
            </div>
            <div>
              {!gridView && pageData.map((employerDetails: any, index: number) => (
                <div className="employers-list-card" key={index}>
                  <EmployerListCard employerDeatils={employerDetails} key={index} />
                </div>
              ))}
            </div>
          </>
        )}
        <div>
          {pageData && pageData.length === 0 && (
            <p className="employers-no-employers">
              There are currently no employers on board
            </p>
          )}
        </div>
      </div>
      <Pagination
        className="employers-pagination-container"
        currentPage={currentPage}
        totalCount={sortedEmployers.length}
        pageSize={numberOfEmployersPerPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default Employers;

